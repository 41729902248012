import {IPriceResponse} from './transfer.model'

export interface ITransaction {
  amountReceived: string
  amountSent: string
  date: string
  depositCode: string
  destinationCountry: string
  destinationCurrency: string
  fundingSource: string
  operatorId: string
  payoutType: string
  recipientId: string
  reservationCode: string
  sourceCountry: string
  sourceCurrency: string
  transactionId: string
  transactionStatus: string
  type: string
  priceResponse?: any
}

export interface IOperation {
  sourceType: string
  isReversal: boolean
  accountNumber?: string | number
  accAmount: number
  accCurrency: string
  trnAmount: number
  trnCurrency: string
  currencyCommission: string
  amountCommission: string
  ledgerEventType: string
  senderName?: string
  recipientName?: string
  recipientId?: string
  transactionType: string
  payoutType: string
  mccGroupName: string
  locationName?: string
  walletLedgerId?: number
  retrReferNum?: string | number
  comment?: string
  pcRegTime: number
  messageCode?: string
  fxRate?: number | string
}

export interface IOperationDetail extends IOperation {
  transactionId?: string
  reservationCode?: string
  senderPhoneNumber?: string
  destinationWalletAccount?: string
  senderWalletId?: string
  fundingSource?: string
  operatorAlias?: string
  amountSource?: string
  currencyDestination?: string
  amountDestination?: string
  currencySource?: string
  transactionComment?: string
  transactionStatus?: string
}

export interface ITransactionDocument {
  uri: string
  documentType: string
  fileName: string
}

export interface ITransactionDetail {
  accountNumber?: string
  agentId: string
  amount: number
  amountSentSrc?: number
  amountDest: number
  bankAccountNumber?: string
  bankAddress?: string
  bankBranch?: string
  bankBranchCode?: string
  bankCity?: string
  bankCode?: string
  bankName?: string
  bankRoutingNumber?: string
  canBeAmended: boolean
  canBeCancelled: boolean
  cardMask?: string
  comment?: string
  invoiceNumber?: string
  complianceFailReason: any
  complianceRequiredDocuments: any
  complianceStatus: string
  depositCode: string
  destinationCurrency: string
  failedComplianceRules: any
  fundingSource: string
  fundingSourceProcessorType: string
  fxRate: number
  ifscCode?: string
  mobileOperatorName?: string
  operatorName: string
  operatorUsedId: string
  paymentStatus: string
  payoutType: string
  pickupCityName?: string
  pickupLocationCode?: string
  pickupLocationName?: string
  priceResponse: IPriceResponse
  receiver: { firstName: string; middleName?: string; lastName: string; id: string, country: string, phoneNumber?: string }
  reservationCode: string
  sourceCurrency: string
  sourceOfFunds: string
  statusMessage: string
  tellerName: string
  transactionDate: string
  transactionFee: number
  transactionId: string
  transactionOrigin: string
  transactionStatus: string
  type: string
  useOfFunds: string
  documents?: ITransactionDocument[]
  recipientWalletId?: string
  paidAmount?: string
}

export interface IFavoriteTransaction {
  amount: string
  cardEnprintedName?: string
  cardMask?: string
  cashPickupName?: string
  depositCode: string
  destinationCountry: string
  reservationCode?: string
  depositingAgentId: string
  fromSenderToPay: boolean
  fundingSource: string
  operatorToUseId: string
  priceResponse: IPriceResponse
  recipientId?: string
  payoutType: string
  recipientWalletId?: string
  senderWalletId?: string
  receiveCurrency: string
  reverse: boolean
  sendCurrency: string
  transactionId: string
  transactionOrigin: string
  transactionStatus: string
  transactionType: string
  useOfFunds: string
  occupation?: string
  sourceOfFunds: string
  accountNumber?: string
  mobileOperatorCode?: string
  mobileOperatorName?: string
  comment?: string
}

export interface ITransactionHistory {
  list: ITransaction[]
  totalCount?: number
  totalPages?: number
  page: number
}

export interface IWalletHistory {
  list: IOperation[]
  pageNum: number
  sessionKey: string | null
  pageKey: string | null
  isFinished: boolean
  walletId?: string | number | null
  cardId?: string | number | null
}

export type TCurrencies = 'ILS' | 'USD' | 'EUR'

export type TCategoryData = { name: string, sum: number, isReversal: boolean, list: IOperation[] }

export type TAnalytica = Record<TCurrencies, TCategoryData>

export type TAnalyticaByGroup = { [groupName: string]: TAnalytica }

export type TotalByCurrency = Record<TCurrencies, number>

export type TMonthAnalytica = {
  spendings: TAnalytica[]
  incomings: TAnalytica[]
  totalSpendings: TotalByCurrency
  totalIncomings: TotalByCurrency
}

export interface IAnalytics {
  report: { [month: string]: TMonthAnalytica }
  sessionKey: string | null
  walletId?: string | number
  cardId?: string | number
}

export interface ITransactionState {
  recentTransactions: ITransaction[]
  recentOperations: IOperation[]
  transactionHistory: ITransactionHistory
  walletHistory: IWalletHistory
  favoriteTransactions: IFavoriteTransaction[]
  currentTransaction: ITransactionDetail | null
  currentOperation: IOperationDetail | null
  analytics: IAnalytics
  loading: string
  transactionLoading: string
  documentLoading: string
  favoriteLoading: string
  analyticaLoading: string
}

export const initialState = (): ITransactionState => ({
  recentTransactions: [],
  recentOperations: [],
  transactionHistory: {
    list: [],
    page: 0,
  },
  walletHistory: {
    list: [],
    pageNum: 0,
    sessionKey: null,
    pageKey: null,
    isFinished: false,
  },
  favoriteTransactions: [],
  currentTransaction: null,
  currentOperation: null,
  analytics: {report: {}, sessionKey: null},
  loading: 'idle',
  transactionLoading: 'idle',
  documentLoading: 'idle',
  favoriteLoading: 'idle',
  analyticaLoading: 'idle',
})

export interface IFilteredPayload {
  page: number
  statuses: string[]
  payouts: string[]
  countries: string[]
  startDate: string
  endDate: string
  recipientName?: string
}

export interface IWalletHistoryPayload {
  pageNum: number
  pageSize: number
  recent?: boolean
  startDate?: string
  endDate?: string
  walletId: number
  cardId: number | null
  mccGroupName?: string
  locationName?: string
  minAccAmount?: number | string
  maxAccAmount?: number | string
  sortField?: string
  sortDirection?: string
}

export interface IAnalyticaPayload {
  walletId: number
  cardId: number | null
  direction: 'CREDIT' | 'DEBIT'
  sessionKey?: string | null
}

export interface IMccGroupData {
  mccGroupName: string
  amountSum: number
  reportData: IOperation[]
}

export interface IAnalyticData {
  currency: string
  amountSum: number
  mccGroupData: IMccGroupData[]
}

export interface IAnalyticResponse {
  year: string
  month: number
  analyticData: IAnalyticData[]
}

export type TStatus = {
  title: string
  values: string[]
  background: string
  color: string
  circleColor?: string
}

export const TRANSACTION_STATUSES: { [key: string]: TStatus } = {
  PENDING: {
    title: 'pending_payment',
    values: ['PRE_TXN'],
    color: '#6A4D8B',
    circleColor: '#A579D8',
    background: 'linear-gradient(180deg, rgba(237, 230, 245, 0) 0%, #EDE6F5 100%)',
  },
  PROCESSING: {
    title: 'processing_payment',
    values: ['FS_CHARGED', 'FS_IN_PROCESS', 'PROCESSING', 'PENDING_REVIEW', 'COMPLIANCE_REVIEW'],
    color: '#33A7FF',
    background: 'linear-gradient(180deg, rgba(217, 238, 255, 0) 0%, #D9EEFF 100%)',
    circleColor: '#33A7FF',
  },
  PAID: {
    title: 'paid_payment',
    values: ['COMPLETE_SUCCESS'],
    background: 'linear-gradient(180deg, rgba(220, 245, 228, 0) 0%, #DCF5E4 100%)',
    color: '#3AC066',
    circleColor: '#3AC066'
  },
  CANCELLED: {
    title: 'cancel_payment',
    values: ['CANCELLED'],
    background: 'linear-gradient(180deg, rgba(255, 233, 216, 0) 0%, #FFE9D8 100%)',
    color: '#AD4A00',
    circleColor: '#AD4A00',
  },
  PENDING_CANCELLATION: {
    title: 'pending_cancel_payment',
    values: ['PENDING_CANCELLATION'],
    background: 'linear-gradient(180deg, rgba(255, 238, 209, 0) 0%, #FFEED1 100%)',
    color: '#BD5F0C',
    circleColor: '#BD5F0C',
  },
  REJECTED: {
    title: 'reject_payment',
    values: ['REJECTED'],
    background: 'linear-gradient(180deg, rgba(255, 233, 233, 0) 0%, #FFE9E9 100%)',
    color: '#B20707',
    circleColor: '#B20707'
  },
  FAIL: {
    title: 'failed_payment',
    values: ['FAIL'],
    background: 'linear-gradient(180deg, rgba(255, 233, 233, 0) 0%, #FFE9E9 100%)',
    color: '#B20707',
    circleColor: '#B20707',
  },
  ALL: {
    title: 'all_statuses',
    values: ['PRE_TXN', 'FS_CHARGED', 'FS_IN_PROCESS', 'PROCESSING', 'PENDING_REVIEW', 'COMPLIANCE_REVIEW', 'COMPLETE_SUCCESS', 'CANCELLED', 'PENDING_CANCELLATION'],
    color: '#33A7FF',
    background: 'linear-gradient(180deg, rgba(217, 238, 255, 0) 0%, #D9EEFF 100%)',
    circleColor: '#33A7FF',
  },
}

export const PAYMENT_STATUSES: { [key: string]: string } = {
  UNPAID: 'unpaid',
  FS_CHARGED: 'paid',
  FS_IN_PROCESS: 'payment_process',
  FS_DENIED: 'payment_declined',
  COLLECTED: 'collected',
  REFUNDED: 'refunded',
  OUTSTANDING: 'outstanding',
}

export type TCategory = {
  title: string
  icon: string
  color: string
}

export const CATEGORIES: { [key: string]: TCategory } = {
  SEND: {
    icon: 'send',
    title: 'mcc_groups:SEND',
    color: '#0090FF',
  },
  TARIFF: {
    icon: 'tariff-category',
    title: 'mcc_groups:TARIFF',
    color: '#8245C8',
  },
  WALLET_TO_WALLET: {
    icon: 'send_wallet',
    title: 'mcc_groups:WALLET_TO_WALLET',
    color: '#00B9B9',
  },
  REVERSAL: {
    icon: 'revert_to_wallet',
    title: 'mcc_groups:REVERSAL',
    color: '#768CFF',
  },
  WALLET_EXCHANGE: {
    icon: 'currency-exchange',
    title: 'wallets:currency_exchange',
    color: '#E28E00',
  },
  WALLET_INTERNAL_TRANSFER: {
    icon: 'between_accounts',
    title: 'wallets:between_your_accounts',
    color: '#E28E00',
  },
  DEPOSIT_TO_WALLET: {
    icon: 'deposit_to_wallet',
    title: 'services:deposit_to_wallet',
    color: '#3AC066',
  },
  DEPOSIT_TO_CARD: {
    icon: 'deposit_to_wallet',
    title: 'services:deposit_to_wallet',
    color: '#3AC066',
  },
  ADDIT_COM: {
    icon: 'additional-commission',
    title: 'tariffs:ADDIT_COM',
    color: '#8245C8',
  },
  'Money transfers': {
    icon: 'money-transfers',
    title: 'support_questions:money_transfers',
    color: '#19B24B',
  },
  'Retail Stores': {
    icon: 'retail-stores',
    title: 'mcc_groups:retail_stores',
    color: '#B280EC',
  },
  'Airlines, Air Carriers': {
    icon: 'airlines',
    title: 'mcc_groups:airlines',
    color: '#6BDFDF',
  },
  'Automobiles and vehicles': {
    icon: 'vehicles',
    title: 'mcc_groups:vehicles',
    color: '#175BC2',
  },
  'Business services': {
    icon: 'business-services',
    title: 'mcc_groups:business',
    color: '#0090FF',
  },
  'Car rental': {
    icon: 'car-rental',
    title: 'mcc_groups:car_rental',
    color: '#F1D900',
  },
  'Cash withdrawal': {
    icon: 'withdraw_cash',
    title: 'issue_card:cash_withdrawal',
    color: '#66BDFF',
  },
  'Clothing Stores': {
    icon: 'clothing-stores',
    title: 'mcc_groups:clothing_stores',
    color: '#F085D9',
  },
  'Contract services': {
    icon: 'contract-services',
    title: 'mcc_groups:contract',
    color: '#FFC564',
  },
  'Entertainments': {
    icon: 'entertainment',
    title: 'mcc_groups:entertainments',
    color: '#E56D39',
  },
  'Government Services': {
    icon: 'government',
    title: 'mcc_groups:government',
    color: '#1778C2',
  },
  'Hotels and motels': {
    icon: 'hotel',
    title: 'mcc_groups:hotels',
    color: '#8BA0FF',
  },
  'Member organizations': {
    icon: 'membership',
    title: 'mcc_groups:member',
    color: '#00B9B9',
  },
  'Personal services': {
    icon: 'personal-services',
    title: 'mcc_groups:personal',
    color: '#EB5757',
  },
  'Professional Services': {
    icon: 'professional-services',
    title: 'mcc_groups:professional',
    color: '#4560F2',
  },
  'Repair services': {
    icon: 'repair',
    title: 'mcc_groups:repair',
    color: '#91DBA9',
  },
  'Sales by mail/phone': {
    icon: 'phome-mail-sales',
    title: 'mcc_groups:sales_mail',
    color: '#E6398C',
  },
  'Service Provider': {
    icon: 'service',
    title: 'mcc_groups:service_provider',
    color: '#46D2F0',
  },
  'Transport': {
    icon: 'transport',
    title: 'mcc_groups:transport',
    color: '#B30707',
  },
  'Utilities and cable services': {
    icon: 'utilities',
    title: 'mcc_groups:utilities',
    color: '#F89500',
  },
  'Various stores': {
    icon: 'shop',
    title: 'mcc_groups:various_stores',
    color: '#C1E000',
  },
  'Wholesale Suppliers and Manufacturers': {
    icon: 'wholesale-supply',
    title: 'mcc_groups:wholesales',
    color: '#15D353',
  },
}

export const walletOperations: { [key: string]: Pick<TCategory, 'icon' | 'title'> } = {
  WALLET_TO_WALLET_RECIPIENT: {
    icon: 'deposit_card',
    title: 'wallets:incoming_transfer',
  },
  WALLET_TO_WALLET_SENDER: {
    icon: 'wallet_to_wallet',
    title: 'wallets:send_stb_union_wallet',
  },
  WALLET_TO_WALLET: {
    icon: 'wallet_to_wallet',
    title: 'wallets:send_stb_union_wallet',
  },
  DEPOSIT_TO_CARD: {
    icon: 'deposit_card',
    title: 'wallets:deposit_money',
  },
  DEPOSIT_TO_WALLET: {
    icon: 'deposit_to_wallet',
    title: 'wallets:deposit_money',
  },
  WALLET_DEPOSIT_CASH: {
    icon: 'deposit_to_wallet',
    title: 'wallets:deposit_money',
  },
  WALLET_DEPOSIT_CARD: {
    icon: 'deposit_to_wallet',
    title: 'wallets:deposit_money',
  },
  WALLET_DEPOSIT_BANK: {
    icon: 'deposit_to_wallet',
    title: 'wallets:deposit_money',
  },
  WALLET_TO_WITHDRAW_WALLET: {
    icon: 'withdraw_wallet',
    title: 'services:withdraw_wallet',
  },
  PAYMENT_FROM_CARD: {
    icon: 'payment_from_card',
    title: 'transfer_purpose_list:Payment for goods/services',
  },
  PAYMENT_FROM_WALLET: {
    icon: 'payment_from_card',
    title: 'transfer_purpose_list:Payment for goods/services',
  },
  WALLET_EXCHANGE: {
    icon: 'currency-exchange',
    title: 'wallets:currency_exchange',
  },
  WALLET_TO_OPERATOR: {
    icon: 'wallet_to_operator',
    title: 'wallets:world_transfer_title',
  },
  WALLET_TO_AGENT: {
    icon: 'wallet_to_operator',
    title: 'wallets:within_israel',
  },
  REVERSAL: {
    icon: 'revert_to_wallet',
    title: 'wallets:reversal',
  },
  REVERT_TO_WALLET: {
    icon: 'revert_to_wallet',
    title: 'wallets:reversal',
  },
  PAYOUT_TO_WALLET: {
    icon: 'payout-to-wallet',
    title: 'wallets:payout_wallet',
  },
  TARIFF: {
    icon: 'tariff-category',
    title: 'tariffs:card_service',
  },
  ADDIT_COM: {
    icon: 'additional-commission',
    title: 'tariffs:ADDIT_COM',
  },
  CASHBACK: {
    icon: 'additional-commission',
    title: 'wallets:CASHBACK',
  },
  WALLET_INTERNAL_TRANSFER_RECIPIENT: {
    icon: 'between_accounts',
    title: 'cards:receiving_from_another_card',
  },
  WALLET_INTERNAL_TRANSFER_SENDER: {
    icon: 'between_accounts',
    title: 'cards:transfer_to_another_card',
  },
  SALARY: {
    icon: "salary-payment",
    title: "services:salary_payment",
  },
}
