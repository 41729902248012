export const LOGIN_ENDPOINT = '/unAuth/login'
export const SIGNUP_ENDPOINT = '/unAuth/signup'
export const EDIT_USER = '/user/edit'

export const CHECK_USER_EXIST = '/unAuth/user-exists'
export const CHECK_PHONE_EXIST = '/unAuth/phoneExistsCheck'
export const CHECK_EMAIL_EXIST = '/unAuth/emailExistsCheck'

export const RESET_PASSWORD_LINK = '/unAuth/reset/getLink'
export const CHECK_RESET_PASSWORD_LINK = '/unAuth/reset/linkIsValid'
export const RESET_PASSWORD = '/unAuth/reset/changePassword'

export const SEND_SIGNUP_VERIFICATION = '/unAuth/sendCode'

export const GET_DOCUMENTS = '/user/mobile/getDocuments'
export const UPLOAD_DOCUMENT = '/user/image/upload'

export const GET_USER_DATA = '/user/accountData'

export const GET_CONTACT_OPTIONS = '/user/getUserContactOptions'
export const SET_CONTACT_OPTIONS = '/user/saveUserContactOptions'
export const CHANGE_PASSWORD = '/user/changePassword'

export const SEND_EMAIL_MESSAGE = '/organization/contact-support'

export const PASSPORT_WITH_SERIES = ['UA', 'BY', 'RU', 'KZ']
