import { call, takeEvery, take, put, select, fork, all, delay, cancelled, ForkEffect } from 'redux-saga/effects'
import { eventChannel, END, EventChannel } from 'redux-saga'
import { PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { get, isEqual } from 'lodash'
import i18n from 'locales/i18n'
import { actionsHandler } from 'services/apiServices/api'
import * as walletApi from 'services/apiServices/wallet.service'
import { verifyPayout, sendPreparePayout, sendConfirmPayout } from 'services/apiServices/transfer.service'
import {
  IWallet,
  ICard,
  IQuestion,
  IAnswer,
  ICardProduct,
  TProductLimits,
  TProductTariff,
  ICardRequest,
  IChangeStatusRequest, ICloseCardRequest,
} from 'model/wallet.model'
import { RootState } from '../store'
import { REQUEST, SUCCESS, FAIL } from 'constants/global.constants'
import {
  getCardProductsActions,
  getWalletsActions,
  getCardsActions,
  getQuestionnaireQuestionsActions,
  sendQuestionnaireAnswersActions,
  getMccGroupsActions,
  getProductTariffActions,
  getProductLimitsActions,
  getIssueCardsActions,
  createIssueCardRequestActions,
  checkQuestionnaire,
  changeCurrencyCardActions,
  changeCardStatusActions,
  getCardDebtsActions,
  getRecipientActions,
  preparePayoutActions,
  confirmPayoutActions,
  setSmsServiceActions,
  deleteSmsServiceActions,
  getCloseCardsActions,
  createCloseCardActions,
  resetPinCodeActions,
  receiveCvvActions,
} from '../reducers/wallet.reducer'
import { openToast, openModal, closeModal } from '../reducers/ui.reducer'
import BreakOpenWallet from 'features/ModalContent/BreakOpenWallet'
import ChangeCurrencySuccess from 'features/ModalContent/ChangeCurrencySuccess'
import { IPayoutPayload } from 'model/transfer.model'
import { UPLOAD_TYPES } from 'model/user.model'
import ActionSuccess from 'features/ModalContent/ActionSuccess'
import DossierSuccess from 'features/ModalContent/DossierSuccess'
import KycNotValid from 'features/ModalContent/KycNotValid'
import ChangeManyRequests from 'features/ModalContent/ChangeManyRequests'
import ChangeNotificationSuccess from 'features/ModalContent/ChangeNotificationSuccess'
import ResetPinSuccess from 'features/ModalContent/ResetPinSuccess'
import { applyComplianceRule, makeTransactionActions } from "../reducers/transfer.reducer"

const organizationId: string = process.env.REACT_APP_ORGANIZATION_ID as string

const countdown = (qty: number, interval = 1000): EventChannel<any> => {
  let counter = 0;
  return eventChannel(emitter => {
    const intervalId = setInterval(() => {
      counter += 1;
      if (counter < qty) {
        emitter(counter);
      } else {
        emitter(END);
      }
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  });
};

export function* getWalletsSaga() {
  try {
    const { data } = yield walletApi.getWalletList();
    const wallets = data?.data.map((wallet: IWallet) => {
      const accountsData = wallet.accounts.reduce((acc, account) => {
        account.order = account.currency === 'ILS'
          ? 1
          : account.currency === 'USD' ? 2 : 3;
        return { ...acc, [account.currency]: account };
      }, {});
      return {
        accountsData,
        accounts: wallet.accounts.sort((a, b) => a.order - b.order),
        id: wallet.id,
        walletNumber: wallet.walletNumber,
      };
    });

    yield put(getWalletsActions[SUCCESS](wallets));
  } catch (err) {
    console.log(err);
    yield put(getWalletsActions[FAIL]());
  }
}

export function* getWalletsCardsSaga({ payload }: PayloadAction<IWallet[]>) {
  try {
    let cards: ICard[] = [];
    for (const wallet of payload) {
      const { data } = yield call(walletApi.getWalletCards, wallet.id);
      const walletCards = (data?.data || []).map((card: ICard) => {
        const accountsData = card.cardAccountsInfo.reduce((acc, account) => {
          account.order = +account.accountType === 1
            ? 1
            : account.currency === 'EUR' ? 3 : 2;
          return {
            ...acc,
            [account.currency]: { ...account },
          };
        }, {});
        return {
          ...card,
          accountsData,
          cardAccountsInfo: card.cardAccountsInfo
            .sort((a, b) => a.order - b.order),
          walletAccountsData: wallet.accountsData,
          walletId: wallet.id,
        };
      });
      cards = [...cards, ...walletCards];
    }

    yield put(getCardsActions[SUCCESS](cards));
  } catch (err) {
    console.log(err);
    yield put(getCardsActions[FAIL]());
  }
}

export function* changeCardCurrencySaga({ payload }: PayloadAction<{ accountNum: string, cardId: string }>) {
  const channel: EventChannel<number> = yield countdown(40, 500);
  try {
    yield call(walletApi.changeCardCurrencyRequest, {
      accountNum: payload.accountNum,
      cardId: payload.cardId,
    });
    const cardList: ICard[] = yield select(({ wallet }) => wallet.cards);
    const currentCard = cardList.find((item: ICard) => +item.id === +payload.cardId);

    while (true) {
      const count: number | undefined = yield take(channel);

      if (!count) {
        yield put(changeCurrencyCardActions[FAIL]());
        break;
      }

      const { data } = yield call(walletApi.getWalletCards, currentCard?.walletId as number);
      const cardData: ICard = (data?.data || []).find((card: ICard) => +card.id === +payload.cardId);
      const desired = (cardData?.cardAccountsInfo || []).find(account => account.accountNum === payload.accountNum);
      if (!!desired && +desired.accountType === 1) {

        yield channel.close();

        const accounts = cardData.cardAccountsInfo.reduce((acc, account) => {
          account.order = +account.accountType === 1
            ? 1
            : account.currency === 'EUR' ? 3 : 2;
          return {
            ...acc,
            [account.currency]: { ...account },
          };
        }, {});

        cardData.accountsData = accounts;
        cardData.walletId = currentCard?.walletId || 0;
        cardData.cardAccountsInfo.sort((a, b) => a.order - b.order);
        yield put(changeCurrencyCardActions[SUCCESS](cardData));
        yield put(openModal({
          type: ChangeCurrencySuccess,
          props: { card: cardData, currency: desired.currency },
          paperClass: 'wide-modal withoutPadding',
        }));
        break;
      }
    }
  } catch (err) {
    if (err.response.data?.error?.errorCode === 'TOO_MANY_REQUESTS') {
      yield put(openModal({
        type: ChangeManyRequests,
        props: { text: 'cards:recently_change_currency' },
        paperClass: 'wide-modal',
      }))
    } else {
      yield put(openToast({
        type: 'error',
        toastMessage: err.message || 'transaction:something_went_wrong_please_try_again_later'
      }))
      yield put(closeModal())
    }
    yield put(changeCurrencyCardActions[FAIL]());
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export function* changeCardStatusSaga({ payload }: PayloadAction<IChangeStatusRequest>) {
  const channel: EventChannel<number> = yield countdown(40, 500);
  const reasonStatuses: { [key: string]: string } = {
    reason_fraud: 'FRAUD_CARD',
    reason_lost: 'LOST_CARD_CAPTURE',
    reason_stolen: 'STOLEN_CARD_CAPTURE',
    reason_desire: 'NOT_PERMITTED',
    reason_not_use: 'NOT_PERMITTED',
    unlock_card: 'VALID_CARD',
    activate_card: 'VALID_CARD',
  }
  try {
    const { cardId, reason } = payload;
    const cardList: ICard[] = yield select(({ wallet }) => wallet.cards);
    const currentCard = cardList.find((item: ICard) => +item.id === +payload.cardId);

    if (currentCard?.isNeedActivation) {
      yield walletApi.activateCard(cardId);
    } else {
      yield walletApi.changeStatusCard(cardId, {
        reason: i18n.t(`cards:${reason}`),
        status: reasonStatuses[reason as string],
      });
    }

    while (true) {
      const count: number | undefined = yield take(channel);

      if (!count) {
        yield put(changeCardStatusActions[FAIL]());
        break;
      }

      const { data } = yield call(walletApi.getWalletCards, currentCard?.walletId as number);
      const cardData: ICard = (data?.data || []).find((card: ICard) => +card.id === +cardId);

      if (cardData?.pcStatus?.status === reasonStatuses[reason as string]) {
        yield channel.close();
        const accounts = cardData.cardAccountsInfo.reduce((acc, account) => {
          account.order = +account.accountType === 1
            ? 1
            : account.currency === 'EUR' ? 3 : 2;
          return {
            ...acc,
            [account.currency]: { ...account },
          };
        }, {});

        cardData.accountsData = accounts;
        cardData.walletId = currentCard?.walletId || 0;
        cardData.cardAccountsInfo.sort((a, b) => a.order - b.order);
        yield put(changeCardStatusActions[SUCCESS](cardData));
        yield put(
          openToast({
            type: 'success',
            toastMessage: payload.reason === 'unlock_card'
              ? ['cards:card_unlocked', 'cards:use_card_again']
              : payload.reason === 'activate_card'
                ? 'cards:card_activated'
                : 'errors:card_blocked'
          })
        )
        break;
      }
    }
  } catch (err) {
    yield put(changeCardStatusActions[FAIL]());
    if (err.response?.data?.error?.errorCode === 'TOO_MANY_REQUESTS') {
      yield put(openModal({
        type: ChangeManyRequests,
        props: { text: 'errors:too_many_req_status' },
        paperClass: 'wide-modal',
      }))
    } else if (err.response?.data?.error?.errorCode === 'AUTHORIZATION_ERROR') {
      yield put(
        openToast({ type: 'error', toastMessage: 'cards:unlocked_online_forbid', })
      )
    } else {
      yield put(
        openToast({
          type: 'error',
          toastMessage: payload.reason === 'unlock_card'
            ? 'cards:failed_unlock'
            : payload.reason === 'activate_card'
              ? err.message || 'transaction:something_went_wrong_please_try_again_later'
              : 'cards:failed_block'
        })
      )
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      console.log('yield cancelled')
      channel.close();
    }
  }
}

export function* setSmsServiceSaga({ payload }: PayloadAction<any>) {
  const channel: EventChannel<number> = yield countdown(40, 500);
  try {
    const { cardId, ...rest } = payload;
    const cardList: ICard[] = yield select(({ wallet }) => wallet.cards);
    const currentCard = cardList.find((item: ICard) => +item.id === +payload.cardId);
    yield walletApi.setSmsServiceCard(cardId, rest);

    while (true) {
      const count: number | undefined = yield take(channel);

      if (!count) {
        yield put(setSmsServiceActions[FAIL]());
        break;
      }

      const { data } = yield call(walletApi.getWalletCards, currentCard?.walletId as number);
      const cardData: ICard = (data?.data || []).find((card: ICard) => +card.id === +cardId);

      if (cardData?.sms === 'PAID') {
        yield channel.close();
        yield put(setSmsServiceActions[SUCCESS]({ cardId: cardData.id }));

        yield put(openModal({
          type: ChangeNotificationSuccess,
          props: { sms: cardData.sms },
          paperClass: 'wide-modal withoutPadding',
        }));

        break;
      }
    }
  } catch (err) {
    yield put(setSmsServiceActions[FAIL]());
    if (err.response?.data?.error?.errorCode === 'TOO_MANY_REQUESTS') {
      yield put(openModal({
        type: ChangeManyRequests,
        props: { text: 'messages:too_many_requests' },
        paperClass: 'wide-modal',
      }))
    } else {
      yield put(
        openToast({
          type: 'error',
          toastMessage: err.message || 'transaction:something_went_wrong_please_try_again_later'
        })
      )
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      console.log('yield cancelled')
      channel.close();
    }
  }
}

export function* deleteSmsServiceSaga({ payload }: PayloadAction<string>) {
  const channel: EventChannel<number> = yield countdown(40, 500);
  try {
    const cardList: ICard[] = yield select(({ wallet }) => wallet.cards);
    const currentCard = cardList.find((item: ICard) => +item.id === +payload);
    yield walletApi.deleteSmsServiceCard(payload);

    while (true) {
      const count: number | undefined = yield take(channel);

      if (!count) {
        yield put(deleteSmsServiceActions[FAIL]());
        break;
      }

      const { data } = yield call(walletApi.getWalletCards, currentCard?.walletId as number);
      const cardData: ICard = (data?.data || []).find((card: ICard) => +card.id === +payload);

      if (cardData?.sms === 'FREE') {
        yield channel.close();
        yield put(deleteSmsServiceActions[SUCCESS]({ cardId: cardData.id }));

        yield put(openModal({
          type: ChangeNotificationSuccess,
          props: { sms: cardData.sms },
          paperClass: 'wide-modal withoutPadding',
        }));

        break;
      }
    }
  } catch (err) {
    yield put(deleteSmsServiceActions[FAIL]());
    if (err.response?.data?.error?.errorCode === 'TOO_MANY_REQUESTS') {
      yield put(openModal({
        type: ChangeManyRequests,
        props: { text: 'messages:too_many_requests' },
        paperClass: 'wide-modal',
      }))
    } else {
      yield put(
        openToast({
          type: 'error',
          toastMessage: err.message || 'transaction:something_went_wrong_please_try_again_later'
        })
      )
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      console.log('yield cancelled')
      channel.close();
    }
  }
}

export function* checkQuestionnaireSaga() {
  try {
    const { userId } = yield select(state => state.user);
    const { issueCard, cards } = yield select(({ wallet }: RootState) => wallet);
    const { navigation } = yield select(({ ui }) => ui)

    const { data } = yield call(walletApi.checkQuestionnaireAnswers, {
      questionnaireName: 'BEFORE_OPENING_CARD',
      userId,
    });
    if (!data.length) {
      throw new Error();
    } else {
      navigation.navigate(`/issue-card/${issueCard.issueValues.cardProductId}`)
    }
  } catch (err) {
    const { navigation } = yield select(({ ui }) => ui)
    navigation.navigate('/questionnaire')
  }
}

export function* getQuestionnaireSaga({ payload }: PayloadAction<string>) {
  try {
    const { questionnaireName, questions } = yield select(({ wallet }) => wallet.questionnaire || {});
    if (payload === questionnaireName) {
      yield put(getQuestionnaireQuestionsActions[SUCCESS]({
        questions,
        questionnaireName,
      }));
      return;
    }
    const { data } = yield call(walletApi.getQuestionnaireList, {
      questionnaireName: payload,
    });

    yield put(getQuestionnaireQuestionsActions[SUCCESS]({
      questions: (data.payload as IQuestion[]).sort((a, b) => Number(a.order) - Number(b.order)),
      questionnaireName: payload,
    }));
  } catch (err) {
    yield put(getQuestionnaireQuestionsActions[FAIL]());
  }
}

export function* sendQuestionnaireAnswersSaga({ payload }: PayloadAction<{ [code: string]: IAnswer }>) {
  try {
    const { answers, questions, questionnaireName } = yield select(({ wallet }: RootState) => wallet.questionnaire);
    const { cardProductId } = yield select(({ wallet }: RootState) => wallet.issueCard.issueValues);

    const fullAnswers: { [code: string]: IAnswer } = { ...answers, ...payload };
    questions.forEach((question: IQuestion) => {
      if (question.answerType === 'BOOLEAN' && !fullAnswers[question.questionCode]) {
        fullAnswers[question.questionCode] = {
          questionCode: question.questionCode,
          answerType: question.answerType,
          answer: "FALSE",
        }
      }
    });
    const answerPayload = {
      questionnaireName,
      payload: Object.values(fullAnswers),
      answerOrigin: 'WEB',
    };
    const { data } = yield call(walletApi.setQuestionnaireAnswers, answerPayload);

    const { navigation } = yield select(({ ui }) => ui)

    if (questionnaireName === 'BEFORE_OPENING_CARD' && fullAnswers.US_PERSON_INFO.answer === 'TRUE') {
      yield put(openModal({
        type: BreakOpenWallet,
        props: { israelTax: false },
        paperClass: 'wide-modal',
      }))
      navigation.navigate('/dashboard')
      return;
    }

    if (questionnaireName === 'BEFORE_OPENING_CARD') {
      navigation.navigate(`/issue-card/${cardProductId}`)
    } else if (questionnaireName === 'KYC_QUEST') {
      const { complianceStatus, actionRequiredReasons } = yield select(({ user }) => user.userMeta);
      const { activeComplianceRules, transferData } = yield select(({ transfer }) => transfer);
      if (!data.valid) {
        yield put(openModal({
          type: KycNotValid,
          paperClass: 'wide-modal withoutPadding',
        }))
      } else if (!!activeComplianceRules.CHECK_DOSSIER?.KYC) {
        yield put(openModal({
          type: DossierSuccess,
          paperClass: 'wide-modal withoutPadding',
          props: { type: 'KYC', transferData }
        }))
      } else if (complianceStatus === 'ACTION_REQUIRED' && (actionRequiredReasons || []).includes('KYC_FORM')) {
        yield put(openModal({
          type: ActionSuccess,
          paperClass: 'wide-modal withoutPadding',
          props: { action: 'KYC_FORM' }
        }))
        navigation.navigate('/dashboard')
      }
    }
    yield put(sendQuestionnaireAnswersActions[SUCCESS]());
  } catch (err) {
    yield put(sendQuestionnaireAnswersActions[FAIL]());
    yield put(openToast({
      type: 'error',
      toastMessage: err.message || 'errors:something_wrong',
    }));
  }
}

export function* getIssueCardRequestsSaga({ payload }: PayloadAction<{ afterCreate: boolean }>) {
  try {
    const { data } = yield walletApi.getIssueCardRequests()
    const pending = data
      .filter((request: ICardRequest) => ([
        'PENDING',
        'READY_TO_DELIVERY',
        'READY_FOR_DELIVERY',
        'PROCESSING',
        'DELIVERY',
        'COMPLIANCE_OFFICER_VALIDATION',
        'COMPLIANCE_OFFICER_DECLINED',
        'COMPLIANCE_OFFICER_APPROVED',
        'COMPLIANCE_OFFICER_ACTION_REQUIRED',
        'WAITING_FOR_ISSUE',
      ].includes(request.issueStatus) && !!request.cardProductId))

    //     PENDING,
    //     PROCESSING,
    //     READY_TO_DELIVERY,
    //     DELIVERY,
    //     READY_FOR_ACTIVATE - появляется карта у юзера
    //     COMPLETE,
    //     CANCELLED,
    //     DECLINED_ACTIVATE,
    //     DELIVERED;
    // WAITING_FOR_ISSUE - выставляется для заявки у которой isVirtualCard=TRUE, в этом статусе valagate ожидает статус выдачи карты от cards

    const result = [...pending].sort((a, b) => moment(a.timeCreated).isBefore(b.timeCreated) ? 1 : -1)

    yield put(getIssueCardsActions[SUCCESS](result))
    if (payload.afterCreate) {
      const { navigation } = yield select(({ ui }) => ui)
      navigation.navigate(`/issue-success/${result[0].id}`)
    }
  } catch (err) {
    yield put(getIssueCardsActions[FAIL]())
  }
}

export function* issueCardSuccessSaga() {
  yield put(getIssueCardsActions[REQUEST]({ afterCreate: true }))
}

export function* getCardDebtsSaga({ payload }: PayloadAction<{ cardId: number, total: boolean }>) {
  try {
    if (payload.total) {
      const { data } = yield walletApi.getTotalDebtsByCard(payload.cardId);
      const shekelDebts = (data?.data || []).find((debt: { currency: string, amount: number }) => debt.currency === 'ILS');
      yield put(getCardDebtsActions[SUCCESS]({
        id: payload.cardId,
        totalDebts: shekelDebts,
      }));
    } else {
      const { data } = yield walletApi.getDebtsByCard(payload.cardId);
      yield put(getCardDebtsActions[SUCCESS]({
        id: payload.cardId,
        debts: data.data,
      }));
    }
  } catch (err) {
    console.log(err.message);
    yield put(getCardDebtsActions[FAIL]());
  }
}

export function* getRecipientSaga({ payload }: PayloadAction<string>) {
  try {
    const { data: { response, status, message } } = yield call(walletApi.getRecipientWallet, {phoneNumber: payload});

    if (status !== 'SUCCESS') {
      throw new Error(message)
    }

    if (!response.lastName) {
      yield put(getRecipientActions[SUCCESS]({ recipient: null, recipientWallet: null }));
      yield call(walletApi.sendSmsNotification, {
        phoneNumber: payload,
      });
    } else if (!response.walletAccounts?.length && !!response.phoneNumber) {
      yield put(getRecipientActions[SUCCESS]({
        recipient: {firstName: response.firstName, lastName: response.lastName, phoneNumber: response.phoneNumber},
        recipientWallet: null
      }));
      yield call(walletApi.sendSmsNotification, {
        phoneNumber: payload,
      });
    } else {
      const wallet = (response.walletAccounts || []).reduce((acc: object, account: {currency: string, accountId: string}) => ({
        ...acc,
        [account.currency]: account.accountId,
      }), {});

      yield put(getRecipientActions[SUCCESS]({
        recipient: {firstName: response.firstName, lastName: response.lastName, phoneNumber: response.phoneNumber},
        recipientWallet: wallet,
      }));
    }
  } catch (err) {
    yield put(getRecipientActions[FAIL]({recipient: null, recipientWallet: null, errorMessage: err.message || ''}));
    if ((err.message || '').includes('not found for passed phone number')) {
      yield call(walletApi.sendSmsNotification, {
        phoneNumber: payload,
      });
    }
  }
}

export function* preparePayoutSaga({ payload }: PayloadAction<IPayoutPayload>) {
  try {
    const { userId } = yield select(state => state.user);

    if (payload.hasOwnProperty('amount') && !payload.amount) {
      throw new Error('No amount')
    }
    if (payload.hasOwnProperty('currency') && !payload.currency) {
      throw new Error('No currency')
    }

    const { data: { data: verifyData, status, message } } = yield call(verifyPayout, payload);

    if (status === 'SUCCESS') {
      const { data: { userDataMismatch, data: payoutData } } = yield call(sendPreparePayout, {
        userId,
        data: verifyData,
        ...payload
      });

      if (!!userDataMismatch && userDataMismatch.length > 0) {
        throw new Error('userDataMismatch');
      }

      if (!!payload.amount && Math.abs(+payoutData.transactionDetails?.amountDest - +payload.amount) > 2) {
        throw new Error('amountMismatch');
      }

      if (!!payload.currency && payoutData.transactionDetails?.destinationCurrency !== payload.currency) {
        throw new Error('amountMismatch');
      }

      yield put(preparePayoutActions[SUCCESS]({
        payoutData,
        payoutPayload: payload,
      }))
    } else {
      throw new Error(message)
    }
  } catch (err) {
    let errorMessage = err.message
    if (errorMessage.includes('already paid out')) {
      errorMessage = i18n.t('errors:payout_already_paid')
    } else if (errorMessage.includes('not found')) {
      errorMessage = i18n.t('errors:payout_not_found')
    } else if (errorMessage === 'No amount') {
      errorMessage = i18n.t('validation:enter_amount')
    } else if (errorMessage === 'No currency') {
      errorMessage = i18n.t('validation:select_currency')
    } else if (errorMessage === 'userDataMismatch') {
      errorMessage = i18n.t('errors:user_payout_mismatch')
    } else if (errorMessage === 'amountMismatch') {
      errorMessage = i18n.t('errors:payout_not_found')
    }
    yield put(preparePayoutActions[FAIL]({ errorMessage }))
  }
}

export function* confirmPayoutSaga() {
  try {
    const { userId } = yield select(state => state.user)
    const { agentId } = yield select((state) => state.configuration)
    const { payoutData } = yield select(({ wallet }) => wallet)
    const { recipientWalletId } = yield select(({ transfer }) => transfer.transferData)

    const payload = {
      organizationId,
      userId,
      agentId,
      data: {
        ...payoutData.payoutData,
        transactionDetails: {
          ...payoutData.payoutData.transactionDetails,
          payoutType: 'PAYOUT_TO_WALLET',
          recipientWalletId,
        }
      },
      ...payoutData.payoutPayload
    }

    const { data: { transactionDetails, status, message, failedComplianceRules } } = yield call(sendConfirmPayout, payload);

    if (status !== 'SUCCESS' && failedComplianceRules && failedComplianceRules.length > 0) {
      const { navigation } = yield select(({ ui }) => ui)
      yield put(makeTransactionActions[FAIL](message || ''))

      const rules = failedComplianceRules.reduce((acc: object, cur: any) => ({
        ...acc,
        [cur.action]: cur,
      }), {});

      if (rules.BLOCK) {
        yield put(
          applyComplianceRule({
            'BLOCK': { ...rules.BLOCK }
          })
        );

        navigation.navigate('/compliance-block');

      } else if (rules.CHECK_DOSSIER) {
        const { failedUserDocs } = rules.CHECK_DOSSIER.failedInfo;
        const missingDocuments = failedUserDocs
          .reduce((acc: object, doc: { failedUserDoc: string }) => ({
            ...acc,
            [doc.failedUserDoc.startsWith('KYC') ? 'KYC' : doc.failedUserDoc]: { ...doc }
          }), {});

        yield put(
          applyComplianceRule({
            'CHECK_DOSSIER': { ...missingDocuments }
          })
        );
        const documents = Object.keys(missingDocuments)

        if (documents.includes(UPLOAD_TYPES.SECOND_ID)) {
          navigation.navigate('/second-document')
        } else if (documents.includes('KYC')) {
          navigation.navigate('/kyc-questionnaire')
        } else {
          navigation.navigate('/transfer-failed')
        }
      } else {
        navigation.navigate('/transfer-failed')
      }
      return
    }

    yield put(confirmPayoutActions[SUCCESS]({ transactionMeta: { ...transactionDetails } }));

    const { navigation } = yield select(({ ui }) => ui)
    navigation.navigate('/payout-success');

    yield put(getWalletsActions[REQUEST]());
  } catch (error) {
    yield put(confirmPayoutActions[FAIL]());
    yield put(openToast({
      type: 'error',
      toastMessage: error.message,
    }));
  }
}

export function* closeCardRequestSaga({ payload }: PayloadAction<{ cardId: number, reason: string }>) {
  try {
    const { phone } = yield select(({ user }) => user.userMeta);
    const { cards } = yield select(({ wallet }) => wallet);
    const card = cards.find((current: ICard) => +current.id === +payload.cardId);
    const { data: { status, message } } = yield call(walletApi.closeCardRequest, {
      walletId: card?.walletId,
      cardId: payload.cardId,
      cardProductName: card?.productCaption,
      maskedPAN: card?.pan.slice(0, 6) + '****' + card.pan.slice(-4),
      reason: payload.reason,
      phoneNumber: phone,
      closeStatus: 'PENDING',
    });

    if (status !== 'SUCCESS') {
      throw new Error(message || '')
    }

    yield put(createCloseCardActions[SUCCESS]())
    yield put(getCloseCardsActions[REQUEST]())

    let counter = 0

    while (counter < 30) {
      const { closeCardRequests } = yield select(({ wallet }) => wallet);
      const request = closeCardRequests.find((request: ICloseCardRequest) => +request.cardId === +payload.cardId);

      if (!!request) {
        const { navigation } = yield select(({ ui }) => ui);
        navigation.navigate(`/close-card-info/${request.id}`);
        break;
      } else {
        counter += 1;
        yield delay(100);
      }
    }
  } catch (err) {
    yield put(createCloseCardActions[FAIL]());
    yield put(openToast({
      type: 'error',
      toastMessage: err.message === 'Too much close card requests'
        ? 'cards:send_request_close_card'
        : err.message || 'errors:something_wrong',
    }));
  }
}

export function* resetPinCodeSaga({ payload }: PayloadAction<string>) {
  try {
    const { data } = yield walletApi.resetPinRequest(payload);
    if (!!data.error || data.status !== 'OK') {
      throw new Error(data.message)
    }
    yield put(resetPinCodeActions[SUCCESS]())

    yield put(openModal({
      type: ResetPinSuccess,
      paperClass: 'wide-modal withoutPadding',
    }));
  } catch (err) {
    yield put(resetPinCodeActions[FAIL]())
    if (err.response?.data?.error?.errorCode === 'TOO_MANY_REQUESTS') {
      yield put(openModal({
        type: ChangeManyRequests,
        props: { text: 'messages:too_many_requests' },
        paperClass: 'wide-modal',
      }))
    } else {
      yield put(
        openToast({
          type: 'error',
          toastMessage: err.message || 'transaction:something_went_wrong_please_try_again_later'
        })
      )
    }
  }
}

export function* receiveCvvSaga({ payload }: PayloadAction<string>) {
  try {
    const { data } = yield walletApi.receiveCvvRequest(payload);
    if (!!data.error || data.status !== 'OK') {
      throw new Error(data.message)
    }
    yield put(receiveCvvActions[SUCCESS]())

    yield put(openModal({
      type: ResetPinSuccess,
      paperClass: 'wide-modal withoutPadding',
      props: { receiveCvv: true },
    }));
  } catch (err) {
    yield put(receiveCvvActions[FAIL]())
    if (err.response?.data?.error?.errorCode === 'TOO_MANY_REQUESTS') {
      yield put(openModal({
        type: ChangeManyRequests,
        props: { text: 'messages:too_many_requests' },
        paperClass: 'wide-modal',
      }))
    } else {
      yield put(
        openToast({
          type: 'error',
          toastMessage: err.message || 'transaction:something_went_wrong_please_try_again_later'
        })
      )
    }
  }
}

const getMccGroupsFork = fork(actionsHandler, {
  actions: getMccGroupsActions,
  method: 'GET',
  responseParser: (response: { data: { data: string[] } }) => response.data.data,
  url: '/api/card/api/v1/public/mobile/mcc/groups',
})

const getCardProductsFork = fork(actionsHandler, {
  actions: getCardProductsActions,
  method: 'GET',
  responseParser: (response: { data: { data: any } }) => response.data.data
    .map((card: Omit<ICardProduct, 'features'> & { features: { key: string }[] }) =>
      ({ ...card, features: card.features.map((feature) => feature.key) })),
  url: '/api/card/api/v1/public/mobile/card-products',
})

const getProductTariffFork = fork(actionsHandler, {
  actions: getProductTariffActions,
  method: 'GET',
  paramsGetter: (productId: string | number) => ({
    params: { cardProductId: productId }
  }),
  responseParser: (response: { data: { data: any } }, productId: string) => {
    const tariff = response.data.data
      .reduce((acc: TProductTariff, trf: any) => ({ ...acc, [trf.tariffType]: { ...trf.cost } }), {});
    return { cardProductId: productId, tariff }
  },
  url: `/api/tariff/api/v1/public/mobile/tariffs`,
})

const getProductLimitsFork = fork(actionsHandler, {
  actions: getProductLimitsActions,
  method: 'GET',
  paramsGetter: (productId: string) => ({
    params: { cardProductId: productId }
  }),
  responseParser: (response: { data: { data: any } }, productId: string) => {
    const limits = response.data.data
      .reduce((acc: TProductLimits, limit: any) => {
        if (acc[limit.code]) {
          return { ...acc, [limit.code]: [...acc[limit.code], { ...limit }] };
        }
        return { ...acc, [limit.code]: [{ ...limit }] };
      }, {});
    return { cardProductId: productId, limits }
  },
  url: `/api/tariff/api/v1/public/mobile/limits`,
})

const createIssueCardRequestFork = fork(actionsHandler, {
  actions: createIssueCardRequestActions,
  method: 'POST',
  paramsGetter: function* (payload: object) {
    const { issueValues } = yield select(({ wallet }: RootState) => wallet.issueCard);
    return { data: { ...issueValues, ...payload, origin: 'WEB' } }
  },
  url: '/users/issue/card',
  toastMessage: { FAIL: true },
})

const getCloseCardRequestsFork = fork(actionsHandler, {
  actions: getCloseCardsActions,
  method: 'GET',
  responseParser: (response: { data: ICloseCardRequest[] }) => response.data.filter(request => [
    'PENDING',
    'PROCESSING',
  ].includes(request.closeStatus)),
  url: '/users/close/cards/user',
})

export default function* rootSaga() {
  yield all<ForkEffect>([
    getMccGroupsFork,
    getCardProductsFork,
    getProductTariffFork,
    getProductLimitsFork,
    createIssueCardRequestFork,
    getCloseCardRequestsFork,
    yield takeEvery(getWalletsActions[REQUEST].toString(), getWalletsSaga),
    yield takeEvery(getWalletsActions[SUCCESS].toString(), getWalletsCardsSaga),
    yield takeEvery(getQuestionnaireQuestionsActions[REQUEST].toString(), getQuestionnaireSaga),
    yield takeEvery(sendQuestionnaireAnswersActions[REQUEST].toString(), sendQuestionnaireAnswersSaga),
    yield takeEvery(getIssueCardsActions[REQUEST].toString(), getIssueCardRequestsSaga),
    yield takeEvery(createIssueCardRequestActions[SUCCESS].toString(), issueCardSuccessSaga),
    yield takeEvery(checkQuestionnaire.toString(), checkQuestionnaireSaga),
    yield takeEvery(changeCurrencyCardActions[REQUEST].toString(), changeCardCurrencySaga),
    yield takeEvery(changeCardStatusActions[REQUEST].toString(), changeCardStatusSaga),
    yield takeEvery(getCardDebtsActions[REQUEST].toString(), getCardDebtsSaga),
    yield takeEvery(getRecipientActions[REQUEST].toString(), getRecipientSaga),
    yield takeEvery(preparePayoutActions[REQUEST].toString(), preparePayoutSaga),
    yield takeEvery(confirmPayoutActions[REQUEST].toString(), confirmPayoutSaga),
    yield takeEvery(setSmsServiceActions[REQUEST].toString(), setSmsServiceSaga),
    yield takeEvery(deleteSmsServiceActions[REQUEST].toString(), deleteSmsServiceSaga),
    yield takeEvery(createCloseCardActions[REQUEST].toString(), closeCardRequestSaga),
    yield takeEvery(resetPinCodeActions[REQUEST].toString(), resetPinCodeSaga),
    yield takeEvery(receiveCvvActions[REQUEST].toString(), receiveCvvSaga),
  ])
}
