import { Box, Typography, TypographyVariant, SxProps } from "@mui/material";
import { useTheme } from '@mui/material/styles'
import React from "react";
import countryService from "../../services/country.service";

interface IAmount {
  variant: string
  withPlus?: boolean
  currency?: string
  amount?: number | string
  signs?: number
  className?: string
  sx?: SxProps
}

const AmountRow = ({ variant, currency = 'ILS', amount = 0, withPlus = false, signs = 2, className = '', sx = {} }: IAmount) => {
  const {typography} = useTheme();

  const calculate = () => {
    switch (variant) {
      case 'body2':
        return 'small'
      case 'body1':
      case 'subtitle1':
        return 'body2'
      case 'subtitle2':
        return 'subtitle1'
      case 'h6':
        return 'h5'
      case 'h5':
        return 'subtitle2'
      case 'h4':
        return 'h5'
      case 'h3':
        return 'h7'
      case 'h2':
        return 'title2'
      case 'h7':
        return 'h4'
      case 'title':
        return 'h3'
      case 'title2':
        return 'title'
      default:
        return variant
    }
  }

  const currencyVariant = calculate()
  const mainSize = Number((typography[variant as TypographyVariant].fontSize as string).replaceAll(/[^0-9^\.]/g, ''))
  const currencySize = Number((typography[currencyVariant as TypographyVariant].fontSize as string).replaceAll(/[^0-9^\.]/g, ''))
  const lineHeight = Number((mainSize / currencySize).toFixed(2))

  return (
    <Box className={"horizontalFlex"} alignItems={"flex-end"}>
      {withPlus && (
        <Typography variant={variant as TypographyVariant} className={className} sx={{ ...sx, lineHeight: 1}}>
          +
        </Typography>
      )}
      <Typography variant={variant as TypographyVariant} className={className} sx={{ ...sx, lineHeight: 1}}>
        {Number(amount || '0').toFixed(signs).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
      </Typography>
      <Box width={4 * currencySize}/>
      <Typography
        variant={currencyVariant as TypographyVariant}
        className={className}
        sx={{ ...sx, lineHeight: currency === 'ILS' ? 1.03 : lineHeight }}
      >
        {countryService.getCurrencySymbolByCurrencyCode(currency)}
      </Typography>
    </Box>
  )
}

export default React.memo(AmountRow)
