import _ from 'lodash'

interface ICountry {
  phonePrefix: string
  fullName: string
  currencySign: string
  currency: string
  countryCode: string
  currencyName: string
  flagClass?: string
  utcOffset?: string
  accountLabel?: string
  latitude?: string | number
  longitude?: string | number
}

interface ICountryData {
  [country: string]: ICountry
}

const CountryData: ICountryData = {
  CH: {
    phonePrefix: '+41',
    fullName: 'Switzerland',
    currencySign: 'CHF',
    currency: 'CHF',
    countryCode: 'CH',
    flagClass: 'ch',
    utcOffset: '+02:00',
    accountLabel: 'IBAN',
    currencyName: 'Swiss Franc',
  },
  SA: {
    phonePrefix: '+966',
    fullName: 'Saudi Arabia',
    currencySign: 'ر.س',
    currency: 'SAR',
    countryCode: 'SA',
    flagClass: 'sa',
    utcOffset: '+03:00',
    accountLabel: 'IBAN',
    currencyName: 'Saudi Riyal',
  },
  HK: {
    phonePrefix: '+852',
    fullName: 'Hong Kong',
    currencySign: 'HK$',
    currency: 'HKD',
    countryCode: 'HK',
    flagClass: 'hk',
    utcOffset: '+08:00',
    accountLabel: 'account_number',
    currencyName: 'Hong Kong Dollar',
  },
  JP: {
    phonePrefix: '+81',
    fullName: 'Japan',
    currencySign: '¥',
    currency: 'JPY',
    countryCode: 'JP',
    flagClass: 'jp',
    utcOffset: '+09:00',
    accountLabel: 'account_number',
    currencyName: 'Yen',
  },
  AE: {
    phonePrefix: '+971',
    fullName: 'United Arab Emirates',
    currencySign: 'د.إ',
    currency: 'AED',
    countryCode: 'AE',
    flagClass: 'ae',
    utcOffset: '+04:00',
    accountLabel: 'account_number',
    currencyName: 'UAE Dirham',
  },
  NZ: {
    phonePrefix: '+64',
    fullName: 'New Zealand',
    currencySign: 'NZ$',
    currency: 'NZD',
    countryCode: 'NZ',
    flagClass: 'nz',
    utcOffset: '+12:00',
    accountLabel: 'account_number',
    currencyName: 'New Zealand Dollar',
  },
  IL: {
    phonePrefix: '+972',
    fullName: 'Israel',
    currencySign: '₪',
    currency: 'ILS',
    countryCode: 'IL',
    flagClass: 'il',
    utcOffset: '+03:00',
    accountLabel: 'account_number',
    currencyName: 'New Israeli Sheqel',
  },
  IN: {
    phonePrefix: '+91',
    fullName: 'India',
    currencySign: '₹',
    currency: 'INR',
    countryCode: 'IN',
    flagClass: 'in',
    utcOffset: '+05:30',
    accountLabel: 'account_number',
    currencyName: 'Indian Rupee',
  },
  PH: {
    phonePrefix: '+63',
    fullName: 'Philippines',
    currencySign: '₱',
    currency: 'PHP',
    countryCode: 'PH',
    flagClass: 'ph',
    utcOffset: '+08:00',
    accountLabel: 'account_number',
    currencyName: 'Philippine Peso',
  },
  US: {
    phonePrefix: '+1',
    fullName: 'USA',
    currencySign: '$',
    currency: 'USD',
    countryCode: 'US',
    flagClass: 'us',
    utcOffset: '+06:00',
    accountLabel: 'account_number',
    currencyName: 'US Dollar',
  },
  FR: {
    phonePrefix: '+33',
    fullName: 'France',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'FR',
    flagClass: 'fr',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  LK: {
    phonePrefix: '+94',
    fullName: 'Sri Lanka',
    currencySign: '₨',
    currency: 'LKR',
    countryCode: 'LK',
    flagClass: 'lk',
    utcOffset: '+05:30',
    latitude: '7.873054',
    longitude: '80.771797',
    accountLabel: 'account_number',
    currencyName: 'Sri Lanka Rupee',
  },
  BD: {
    phonePrefix: '+880',
    fullName: 'Bangladesh',
    currencySign: '৳',
    currency: 'BDT',
    countryCode: 'BD',
    flagClass: 'bd',
    utcOffset: '+06:00',
    latitude: '23.684994',
    longitude: '90.356331',
    accountLabel: 'account_number',
    currencyName: 'Taka',
  },
  TR: {
    phonePrefix: '+90',
    fullName: 'Turkey',
    currencySign: '₺',
    currency: 'TRY',
    countryCode: 'TR',
    flagClass: 'tr',
    utcOffset: '+03:00',
    latitude: '38.963745',
    longitude: '35.243322',
    accountLabel: 'account_number',
    currencyName: 'Turkish Lira',
  },
  NG: {
    phonePrefix: '+234',
    fullName: 'Nigeria',
    currencySign: '₦',
    currency: 'NGN',
    countryCode: 'NG',
    flagClass: 'ng',
    utcOffset: '+01:00',
    latitude: '9.081999',
    longitude: '8.675277',
    accountLabel: 'account_number',
    currencyName: 'Naira',
  },
  ZA: {
    phonePrefix: '+27',
    fullName: 'South Africa',
    currencySign: 'R',
    currency: 'ZAR',
    countryCode: 'ZA',
    flagClass: 'za',
    utcOffset: '+02:00',
    latitude: '-30.559482',
    longitude: '22.937506',
    accountLabel: 'account_number',
    currencyName: 'Rand',
  },
  ES: {
    phonePrefix: '+34',
    fullName: 'Spain',
    currencySign: '€',
    currency: 'ESP',
    countryCode: 'ES',
    flagClass: 'es',
    utcOffset: '+01:00',
    latitude: '40.463667',
    longitude: '-3.749220',
    accountLabel: 'account_number',
    currencyName: 'Spanish Peseta',
  },
  DE: {
    phonePrefix: '+49',
    fullName: 'Germany',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'DE',
    flagClass: 'de',
    utcOffset: '+01:00',
    latitude: '51.165691',
    longitude: '10.451526',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  JO: {
    phonePrefix: '+962',
    fullName: 'Jordan',
    currencySign: 'JD',
    currency: 'JOD',
    countryCode: 'JO',
    flagClass: 'jo',
    utcOffset: '+02:00',
    latitude: '30.585164',
    longitude: '36.238414',
    accountLabel: 'account_number',
    currencyName: 'Jordanian Dinar',
  },
  CA: {
    phonePrefix: '+1',
    fullName: 'Canada',
    currencySign: 'CA$',
    currency: 'CAD',
    countryCode: 'CA',
    flagClass: 'ca',
    utcOffset: '-04:00',
    latitude: '56.130366',
    longitude: '-106.346771',
    accountLabel: 'account_number',
    currencyName: 'Canadian Dollar',
  },
  GR: {
    phonePrefix: '+30',
    fullName: 'Greece',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'GR',
    flagClass: 'gr',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  NL: {
    phonePrefix: '+31',
    fullName: 'Netherlands',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'NL',
    flagClass: 'nl',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  BE: {
    phonePrefix: '+32',
    fullName: 'Belgium',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'BE',
    flagClass: 'be',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  GI: {
    phonePrefix: '+350',
    fullName: 'Gibraltar',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'GI',
    flagClass: 'gi',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  PT: {
    phonePrefix: '+351',
    fullName: 'Portugal',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'PT',
    flagClass: 'pt',
    utcOffset: '-01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  LU: {
    phonePrefix: '+352',
    fullName: 'Luxembourg',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'LU',
    flagClass: 'lu',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  IE: {
    phonePrefix: '+353',
    fullName: 'Ireland',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'IE',
    flagClass: 'ie',
    utcOffset: '00:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'Sort Code',
    currencyName: 'Euro',
  },
  IS: {
    phonePrefix: '+354',
    fullName: 'Iceland',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'IS',
    flagClass: 'is',
    utcOffset: '00:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  MT: {
    phonePrefix: '+356',
    fullName: 'Malta',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'MT',
    flagClass: 'mt',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  CY: {
    phonePrefix: '+357',
    fullName: 'Cyprus',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'CY',
    flagClass: 'cy',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  FI: {
    phonePrefix: '+358',
    fullName: 'Finland',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'FI',
    flagClass: 'fi',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  BG: {
    phonePrefix: '+359',
    fullName: 'Bulgaria',
    currencySign: '€',
    currency: 'BGN',
    countryCode: 'BG',
    flagClass: 'bg',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  HU: {
    phonePrefix: '+36',
    fullName: 'Hungary',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'HU',
    flagClass: 'hu',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  LT: {
    phonePrefix: '+370',
    fullName: 'Lithuania',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'LT',
    flagClass: 'lt',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  LV: {
    phonePrefix: '+371',
    fullName: 'Latvia',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'LV',
    flagClass: 'lv',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  EE: {
    phonePrefix: '+372',
    fullName: 'Estonia',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'EE',
    flagClass: 'ee',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  HR: {
    phonePrefix: '+385',
    fullName: 'Croatia',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'HR',
    flagClass: 'hr',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  SI: {
    phonePrefix: '+386',
    fullName: 'Slovenia',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'SI',
    flagClass: 'si',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  IT: {
    phonePrefix: '+39',
    fullName: 'Italy',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'IT',
    flagClass: 'it',
    utcOffset: '+01:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  RO: {
    phonePrefix: '+40',
    fullName: 'Romania',
    currencySign: 'L',
    currency: 'RON',
    countryCode: 'RO',
    flagClass: 'ro',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  CZ: {
    phonePrefix: '+420',
    fullName: 'Czech Republic',
    currencySign: '€',
    currency: 'CZK',
    countryCode: 'CZ',
    flagClass: 'cz',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  SK: {
    phonePrefix: '+421',
    fullName: 'Slovakia',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'SK',
    flagClass: 'sk',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  LI: {
    phonePrefix: '+423',
    fullName: 'Liechtenstein',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'LI',
    flagClass: 'li',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  AT: {
    phonePrefix: '+43',
    fullName: 'Austria',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'AT',
    flagClass: 'at',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  SE: {
    phonePrefix: '+46',
    fullName: 'Sweden',
    currencySign: 'Skr',
    currency: 'SEK',
    countryCode: 'SE',
    flagClass: 'se',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Swedish Krona',
  },
  NO: {
    phonePrefix: '+47',
    fullName: 'Norway',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'NO',
    flagClass: 'no',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  PL: {
    phonePrefix: '+48',
    fullName: 'Poland',
    currencySign: 'zł',
    currency: 'PLN',
    countryCode: 'PL',
    flagClass: 'pl',
    utcOffset: '+01:00',
    accountLabel: 'account_number',
    currencyName: 'Polish Zloty',
  },
  GB: {
    phonePrefix: '+44',
    fullName: 'United Kingdom',
    currencySign: '£',
    currency: 'GBP',
    countryCode: 'GB',
    flagClass: 'gb',
    utcOffset: '+00:00',
    latitude: '55.3781',
    longitude: '3.4360',
    accountLabel: 'Sort Code',
    currencyName: 'Pound Sterling',
  },
  AU: {
    phonePrefix: '+61',
    fullName: 'Australia',
    currencySign: 'AU$',
    currency: 'AUD',
    countryCode: 'AU',
    flagClass: 'au',
    utcOffset: '+05:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Australian Dollar',
  },
  TH: {
    phonePrefix: '+66',
    fullName: 'Thailand',
    currencySign: '฿',
    currency: 'THB',
    countryCode: 'TH',
    flagClass: 'th',
    utcOffset: '+07:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Baht',
  },
  CN: {
    phonePrefix: '+86',
    fullName: 'China',
    currencySign: 'CN¥',
    currency: 'CNY',
    countryCode: 'CN',
    flagClass: 'cn',
    utcOffset: '+08:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Yuan Renminbi',
  },
  VN: {
    phonePrefix: '+84',
    fullName: 'Vietnam',
    currencySign: '₫',
    currency: 'VND',
    countryCode: 'VN',
    flagClass: 'vn',
    utcOffset: '+07:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Dong',
  },
  MY: {
    phonePrefix: '+60',
    fullName: 'Malaysia',
    currencySign: 'RM',
    currency: 'MYR',
    countryCode: 'MY',
    flagClass: 'my',
    utcOffset: '+07:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Malaysian Ringgit',
  },
  SG: {
    phonePrefix: '+65',
    fullName: 'Singapore',
    currencySign: 'S$',
    currency: 'SGD',
    countryCode: 'SG',
    flagClass: 'sg',
    utcOffset: '+08:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Singapore Dollar',
  },
  NP: {
    phonePrefix: '+977',
    fullName: 'Nepal',
    currencySign: 'रू',
    currency: 'NPR',
    countryCode: 'NP',
    flagClass: 'np',
    utcOffset: '+05:45',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Nepalese Rupee',
  },
  KH: {
    phonePrefix: '+855',
    fullName: 'Cambodia',
    currencySign: '៛',
    currency: 'KHR',
    countryCode: 'KH',
    flagClass: 'kh',
    utcOffset: '+07:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Riel',
  },
  PK: {
    phonePrefix: '+92',
    fullName: 'Pakistan',
    currencySign: '₨',
    currency: 'PKR',
    countryCode: 'PK',
    flagClass: 'pk',
    utcOffset: '+05:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Pakistan Rupee',
  },
  MN: {
    phonePrefix: '+976',
    fullName: 'Mongolia',
    currencySign: '₮',
    currency: 'MNT',
    countryCode: 'MN',
    flagClass: 'mn',
    utcOffset: '+08:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Tugrik',
  },
  AF: {
    phonePrefix: '+93',
    fullName: 'Afghanistan',
    currencySign: '؋',
    currency: 'AFN',
    countryCode: 'AF',
    flagClass: 'af',
    utcOffset: '+04:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Afghan Afghani',
  },
  UA: {
    phonePrefix: '+380',
    fullName: 'Ukraine',
    currencySign: '₴',
    currency: 'UAH',
    countryCode: 'UA',
    flagClass: 'ua',
    utcOffset: '+02:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Ukraine Hryvnia',
  },
  GH: {
    phonePrefix: '+233',
    fullName: 'Ghana',
    currencySign: 'GH₵',
    currency: 'GHS',
    countryCode: 'GH',
    flagClass: 'gh',
    utcOffset: '+00:00',
    latitude: '7.946527',
    longitude: '1.023194',
    accountLabel: 'account_number',
    currencyName: 'Ghana Cedi',
  },
  ER: {
    phonePrefix: '+291',
    fullName: 'Eritrea',
    currencySign: 'Nfk',
    currency: 'ERN',
    countryCode: 'ER',
    flagClass: 'er',
    utcOffset: '+03:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Eritrea Nakfa',
  },
  TJ: {
    phonePrefix: '+992',
    fullName: 'Tajikistan',
    currencySign: 'SM',
    currency: 'TJS',
    countryCode: 'TJ',
    flagClass: 'tj',
    utcOffset: '+05:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Tajikistan Somoni',
  },
  GY: {
    phonePrefix: '+592',
    fullName: 'Guyana',
    currencySign: 'GY$',
    currency: 'GYD',
    countryCode: 'GY',
    flagClass: 'gy',
    utcOffset: '-4:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Guyana Dollar',
  },
  PE: {
    phonePrefix: '+51',
    fullName: 'Peru',
    currencySign: 'S/.',
    currency: 'PEN',
    countryCode: 'PE',
    flagClass: 'pe',
    utcOffset: '-5:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Peru Sol',
  },
  MA: {
    phonePrefix: '+212',
    fullName: 'Morocco',
    currencySign: 'د.م.',
    currency: 'MAD',
    countryCode: 'MA',
    flagClass: 'ma',
    utcOffset: '+0:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Morocco Dirham',
  },
  KE: {
    phonePrefix: '+254',
    fullName: 'Kenya',
    currencySign: 'Ksh',
    currency: 'KES',
    countryCode: 'KE',
    flagClass: 'ke',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Kenya Shilling',
  },
  MD: {
    phonePrefix: '+373',
    fullName: 'Moldova',
    currencySign: 'lei',
    currency: 'MDL',
    countryCode: 'MD',
    flagClass: 'md',
    utcOffset: '+2:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Moldova Leu',
  },
  RU: {
    phonePrefix: '+7',
    fullName: 'Russia',
    currencySign: '₽',
    currency: 'RUB',
    countryCode: 'RU',
    flagClass: 'ru',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Russia Ruble',
  },
  AR: {
    phonePrefix: '+54',
    fullName: 'Argentina',
    currencySign: 'AR$',
    currency: 'ARS',
    countryCode: 'AR',
    flagClass: 'ar',
    utcOffset: '-3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Argentina Peso',
  },
  UZ: {
    phonePrefix: '+998',
    fullName: 'Uzbekistan',
    currencySign: "So'm",
    currency: 'UZS',
    countryCode: 'UZ',
    flagClass: 'uz',
    utcOffset: '+5:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Uzbekistan Som',
  },
  BR: {
    phonePrefix: '+55',
    fullName: 'Brazil',
    currencySign: 'R$',
    currency: 'BRL',
    countryCode: 'BR',
    flagClass: 'br',
    utcOffset: '-3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Brazil Real',
  },
  BJ: {
    phonePrefix: '+229',
    fullName: 'Benin',
    currencySign: '₣',
    currency: 'XOF',
    countryCode: 'BJ',
    flagClass: 'bj',
    utcOffset: '+1:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Benin Franc',
  },
  GF: {
    phonePrefix: '+594',
    fullName: 'French Guiana',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'GF',
    flagClass: 'gf',
    utcOffset: '-3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  GE: {
    phonePrefix: '+995',
    fullName: 'Georgia',
    currencySign: 'ლ',
    currency: 'GEL',
    countryCode: 'GE',
    flagClass: 'ge',
    utcOffset: '+4:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Georgia Lari',
  },
  BY: {
    phonePrefix: '+375',
    fullName: 'Belarus',
    currencySign: 'Br',
    currency: 'BYN',
    countryCode: 'BY',
    flagClass: 'by',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Belarus Ruble',
  },
  AZ: {
    phonePrefix: '+994',
    fullName: 'Azerbaijan',
    currencySign: '₼',
    currency: 'AZN',
    countryCode: 'AZ',
    flagClass: 'az',
    utcOffset: '+4:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Azerbaijan Manat',
  },
  PY: {
    phonePrefix: '+595',
    fullName: 'Paraguay',
    currencySign: '₲',
    currency: 'PYG',
    countryCode: 'PY',
    flagClass: 'py',
    utcOffset: '-3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Paraguay Guarani',
  },
  ET: {
    phonePrefix: '+251',
    fullName: 'Ethiopia',
    currencySign: 'Br',
    currency: 'ETB',
    countryCode: 'ET',
    flagClass: 'et',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Ethiopia Birr',
  },
  KG: {
    phonePrefix: '+996',
    fullName: 'Kyrgyzstan',
    currencySign: 'KGS',
    currency: 'KGS',
    countryCode: 'KG',
    flagClass: 'kg',
    utcOffset: '+6:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Kyrgyzstan Som',
  },
  KZ: {
    phonePrefix: '+7',
    fullName: 'Kazakhstan',
    currencySign: '₸',
    currency: 'KZT',
    countryCode: 'KZ',
    flagClass: 'kz',
    utcOffset: '+6:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Kazakhstan Tenge',
  },
  AL: {
    phonePrefix: '+355',
    fullName: 'Albania',
    currencySign: 'L',
    currency: 'ALL',
    countryCode: 'AL',
    flagClass: 'al',
    utcOffset: '+1:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Albania Lek',
  },
  AM: {
    phonePrefix: '+374',
    fullName: 'Armenia',
    currencySign: '֏',
    currency: 'AMD',
    countryCode: 'AM',
    flagClass: 'am',
    utcOffset: '+4:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Armenia Dram',
  },
  CG: {
    phonePrefix: '+242',
    fullName: 'Republic of the Congo',
    currencySign: 'CF',
    currency: 'XAF',
    countryCode: 'CG',
    flagClass: 'cg',
    utcOffset: '+1:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'CFA Franc',
  },
  CL: {
    phonePrefix: '+56',
    fullName: 'Chile',
    currencySign: 'CL$',
    currency: 'CLP',
    countryCode: 'CL',
    flagClass: 'cl',
    utcOffset: '-3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Chile Peso',
  },
  MX: {
    phonePrefix: '+52',
    fullName: 'Mexico',
    currencySign: 'MX$',
    currency: 'MXN',
    countryCode: 'MX',
    flagClass: 'mx',
    utcOffset: '-6:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Mexico Peso',
  },
  CD: {
    phonePrefix: '+243',
    fullName: 'Democratic Republic of the Congo',
    currencySign: 'FC',
    currency: 'CDF',
    countryCode: 'CD',
    flagClass: 'cd',
    utcOffset: '-6:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Congolese franc',
  },
  EG: {
    phonePrefix: '+20',
    fullName: 'Egypt',
    currencySign: 'ج.م',
    currency: 'EGP',
    countryCode: 'EG',
    flagClass: 'eg',
    utcOffset: '+2:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Egypt pound',
  },
  LB: {
    phonePrefix: '+961',
    fullName: 'Lebanon',
    currencySign: 'ل.ل',
    currency: 'LBP',
    countryCode: 'LB',
    flagClass: 'lb',
    utcOffset: '+2:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Lebanon pound',
  },
  YE: {
    phonePrefix: '+967',
    fullName: 'Yemen',
    currencySign: '﷼',
    currency: 'YER',
    countryCode: 'YE',
    flagClass: 'ye',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Yemen rial',
  },
  SO: {
    phonePrefix: '+252',
    fullName: 'Somalia',
    currencySign: 'S',
    currency: 'SOS',
    countryCode: 'SO',
    flagClass: 'so',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Somali shilling',
  },
  TW: {
    phonePrefix: '+886',
    fullName: 'Taiwan',
    currencySign: 'NT$',
    currency: 'TWD',
    countryCode: 'TW',
    flagClass: 'tw',
    utcOffset: '+8:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'New Taiwan dollar',
  },
  ZW: {
    phonePrefix: '+263',
    fullName: 'Zimbabwe',
    currencySign: 'Z$',
    currency: 'ZWL',
    countryCode: 'ZW',
    flagClass: 'zw',
    utcOffset: '+2:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Zimbabwean dollar',
  },
  TZ: {
    phonePrefix: '+255',
    fullName: 'Tanzania',
    currencySign: 'TSh',
    currency: 'TZS',
    countryCode: 'TZ',
    flagClass: 'tz',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Tanzanian shilling',
  },
  CI: {
    phonePrefix: '+225',
    fullName: 'Ivory Coast',
    currencySign: 'CF',
    currency: 'XOF',
    countryCode: 'CI',
    flagClass: 'ci',
    utcOffset: '',
    accountLabel: 'account_number',
    currencyName: 'CFA Franc',
  },
  VE: {
    phonePrefix: '+58',
    fullName: 'Venezuela',
    currencySign: 'Bs',
    currency: 'VEF',
    countryCode: 'VE',
    flagClass: 've',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Venezuelan bolívar',
  },
  TG: {
    phonePrefix: '+228',
    fullName: 'Togo',
    currencySign: 'CF',
    currency: 'XOF',
    countryCode: 'TG',
    flagClass: 'tg',
    utcOffset: '+0:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'CFA Franc',
  },
  SD: {
    phonePrefix: '+249',
    fullName: 'Sudan',
    currencySign: 'ج.س',
    currency: 'SDG',
    countryCode: 'SD',
    flagClass: 'sd',
    utcOffset: '+2:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Sudanese pound',
  },
  TM: {
    phonePrefix: '+993',
    fullName: 'Turkmenistan',
    currencySign: 'T',
    currency: 'TMT',
    countryCode: 'TM',
    flagClass: 'tm',
    utcOffset: '+5:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Manat',
  },
  PA: {
    phonePrefix: '+507',
    fullName: 'Panama',
    currencySign: 'B/.',
    currency: 'PAB',
    countryCode: 'PA',
    flagClass: 'pa',
    utcOffset: '-5:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Panamanian balboa',
  },
  UG: {
    phonePrefix: '+256',
    fullName: 'Uganda',
    currencySign: 'USh',
    currency: 'UGX',
    countryCode: 'UG',
    flagClass: 'ug',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Ugandan shilling',
  },
  ZM: {
    phonePrefix: '+260',
    fullName: 'Zambia',
    currencySign: 'K',
    currency: 'ZMW',
    countryCode: 'ZM',
    flagClass: 'zm',
    utcOffset: '+2:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Zambian kwacha',
  },
  UY: {
    phonePrefix: '+598',
    fullName: 'Uruguay',
    currencySign: '$U',
    currency: 'UYU',
    countryCode: 'UY',
    flagClass: 'uy',
    utcOffset: '-3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Uruguayan peso',
  },
  SS: {
    phonePrefix: '+211',
    fullName: 'South Sudan',
    currencySign: 'SS£',
    currency: 'SSP',
    countryCode: 'SS',
    flagClass: 'ss',
    utcOffset: '+3:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'South Sudanese pound',
  },
  GM: {
    phonePrefix: '+220',
    fullName: 'Gambia',
    currencySign: 'D',
    currency: 'GMD',
    countryCode: 'GM',
    flagClass: 'gm',
    utcOffset: '+0:00',
    latitude: '0',
    longitude: '0',
    accountLabel: 'account_number',
    currencyName: 'Gambia Dalasi',
  },
  IQ: {
    phonePrefix: '+964',
    fullName: 'Iraq',
    currencySign: 'د.ع.',
    currency: 'IQD',
    countryCode: 'IQ',
    flagClass: 'iq',
    accountLabel: 'account_number',
    currencyName: 'Iraqi Dinar',
  },
  GT: {
    phonePrefix: ' +502 ',
    fullName: 'Guatemala',
    currencySign: 'Q',
    currency: 'GTQ',
    countryCode: 'GT',
    flagClass: 'gt',
    accountLabel: 'account_number',
    currencyName: 'Guatemalan quetzal',
  },
  GD: {
    phonePrefix: '+1473',
    fullName: 'Grenada',
    currencySign: '$',
    currency: 'XCD',
    countryCode: 'GD',
    flagClass: 'gd',
    accountLabel: 'account_number',
    currencyName: 'Grenadian dollar',
  },
  HT: {
    phonePrefix: '+509',
    fullName: 'Haiti',
    currencySign: 'G',
    currency: 'HTG',
    countryCode: 'HT',
    flagClass: 'ht',
    accountLabel: 'account_number',
    currencyName: 'Haitian Gourde',
  },
  DM: {
    phonePrefix: '+1767',
    fullName: 'Dominica',
    currencySign: 'RD$',
    currency: 'DOP',
    countryCode: 'DM',
    flagClass: 'dm',
    accountLabel: 'account_number',
    currencyName: 'Dominican Peso',
  },
  AG: {
    phonePrefix: '+1268',
    fullName: 'Antigua and Barbuda',
    currencySign: '$',
    currency: 'XCD',
    countryCode: 'AG',
    flagClass: 'ag',
    accountLabel: 'account_number',
    currencyName: 'East Caribbean dollar',
  },
  AI: {
    phonePrefix: '+1264',
    fullName: 'Anguilla',
    currencySign: '$',
    currency: 'XCD',
    countryCode: 'AI',
    flagClass: 'ai',
    accountLabel: 'account_number',
    currencyName: 'East Caribbean dollar',
  },
  BB: {
    phonePrefix: '+1246',
    fullName: 'Barbados',
    currencySign: 'Bds$',
    currency: 'BBD',
    countryCode: 'BB',
    flagClass: 'bb',
    accountLabel: 'account_number',
    currencyName: 'Barbadian',
  },
  EC: {
    phonePrefix: '+593',
    fullName: 'Ecuador',
    currencySign: '$',
    currency: 'USD',
    countryCode: 'EC',
    flagClass: 'ec',
    accountLabel: 'account_number',
    currencyName: 'US Dollar',
  },
  SV: {
    phonePrefix: '+503',
    fullName: 'El Salvador',
    currencySign: '$',
    currency: 'SVC',
    countryCode: 'SV',
    flagClass: 'sv',
    accountLabel: 'account_number',
    currencyName: 'Salvadoran Colon',
  },
  CM: {
    phonePrefix: '+237',
    fullName: 'Cameroon',
    currencySign: 'c',
    currency: 'XAF',
    countryCode: 'CM',
    flagClass: 'cm',
    accountLabel: 'account_number',
    currencyName: 'Central African CFA Franc',
  },
  JM: {
    phonePrefix: '+1876',
    fullName: 'Jamaica',
    currencySign: 'J$',
    currency: 'JMD',
    countryCode: 'JM',
    flagClass: 'jm',
    accountLabel: 'account_number',
    currencyName: 'Jamaican Dollar',
  },
  AW: {
    phonePrefix: '+297',
    fullName: 'Aruba',
    currencySign: 'Afl.',
    currency: 'AWG',
    countryCode: 'AW',
    flagClass: 'aw',
    accountLabel: 'account_number',
    currencyName: 'Aruban florin',
  },
  HN: {
    phonePrefix: '+504',
    fullName: 'Honduras',
    currencySign: 'L',
    currency: 'HNL',
    countryCode: 'HN',
    flagClass: 'hn',
    accountLabel: 'account_number',
    currencyName: 'Honduran lempira',
  },
  BI: {
    phonePrefix: '+257',
    fullName: 'Burundi',
    currencySign: 'Fbu',
    currency: 'BIF',
    countryCode: 'BI',
    flagClass: 'bi',
    accountLabel: 'account_number',
    currencyName: 'Burundi Franc',
  },
  CO: {
    phonePrefix: '+57',
    fullName: 'Colombia',
    currencySign: 'CO$',
    currency: 'COP',
    countryCode: 'CO',
    flagClass: 'co',
    accountLabel: 'account_number',
    currencyName: 'Colombian peso',
  },
  ID: {
    phonePrefix: '+62',
    fullName: 'Indonesia',
    currencySign: 'Rp',
    currency: 'IDR',
    countryCode: 'ID',
    flagClass: 'id',
    accountLabel: 'account_number',
    currencyName: 'Indonesian rupiah',
  },
  GW: {
    phonePrefix: '+245',
    fullName: 'Guinea-Bissau',
    currencySign: 'Fr',
    currency: 'XOF',
    countryCode: 'GW',
    flagClass: 'gw',
    accountLabel: 'account_number',
    currencyName: 'West African CFA franc',
  },
  KY: {
    phonePrefix: '+1345',
    fullName: 'Cayman Islands',
    currencySign: '$',
    currency: 'KYD',
    countryCode: 'KY',
    flagClass: 'ky',
    accountLabel: 'account_number',
    currencyName: 'Cayman Islands dollar',
  },
  VG: {
    phonePrefix: '+1284',
    fullName: 'British Virgin Islands',
    currencySign: '$',
    currency: 'USD',
    countryCode: 'VG',
    flagClass: 'vg',
    accountLabel: 'account_number',
    currencyName: 'US Dollar',
  },
  BO: {
    phonePrefix: '+591',
    fullName: 'Bolivia',
    currencySign: '$b.',
    currency: 'BOB',
    countryCode: 'BO',
    flagClass: 'bo',
    accountLabel: 'account_number',
    currencyName: 'Bolivian peso',
  },
  FJ: {
    phonePrefix: '+679',
    fullName: 'Fiji',
    currencySign: 'FJ$',
    currency: 'FJD',
    countryCode: 'FJ',
    flagClass: 'fj',
    accountLabel: 'account_number',
    currencyName: 'Fijian dollar',
  },
  GN: {
    phonePrefix: '+224',
    fullName: 'Guinea',
    currencySign: 'FG',
    currency: 'GNF',
    countryCode: 'GN',
    flagClass: 'gn',
    accountLabel: 'account_number',
    currencyName: 'Guinean franc',
  },
  CR: {
    phonePrefix: '+506',
    fullName: 'Costa Rica',
    currencySign: '₡',
    currency: 'CRC',
    countryCode: 'CR',
    flagClass: 'cr',
    accountLabel: 'account_number',
    currencyName: 'Costa Rican Colon',
  },
  RW: {
    phonePrefix: '+250',
    fullName: 'Rwanda',
    currency: 'RWF',
    countryCode: 'RW',
    accountLabel: 'account_number',
    currencyName: 'Rwandan Franc',
    currencySign: 'R₣',
  },
  RS: {
    phonePrefix: '+381',
    fullName: 'Serbia',
    currency: 'RSD',
    countryCode: 'RS',
    accountLabel: 'account_number',
    currencyName: 'Serbian Dinar',
    currencySign: 'din',
  },
  DZ: {
    phonePrefix: '+213',
    fullName: 'Algeria',
    currency: 'DZD',
    countryCode: 'DZ',
    accountLabel: 'account_number',
    currencyName: 'Algeria Dinar',
    currencySign: 'DA',
  },
  SN: {
    phonePrefix: '+221',
    fullName: 'Senegal',
    currency: 'XOF',
    countryCode: 'SN',
    accountLabel: 'account_number',
    currencyName: 'Frank CFA',
    currencySign: '₣',
  },
  ML: {
    phonePrefix: '+223',
    fullName: 'Mali',
    currency: 'XOF',
    countryCode: 'ML',
    accountLabel: 'account_number',
    currencyName: 'Frank CFA',
    currencySign: '₣',
  },
  MZ: {
    phonePrefix: '+258',
    fullName: 'Mozambique',
    currency: 'MZN',
    countryCode: 'MZ',
    accountLabel: 'account_number',
    currencyName: 'Metical',
    currencySign: 'MT',
  },
  SL: {
    phonePrefix: '+232',
    fullName: 'Sierra Leone',
    currency: 'SLL',
    countryCode: 'SL',
    accountLabel: 'account_number',
    currencyName: 'Leone',
    currencySign: 'Le',
  },
  TD: {
    phonePrefix: '+235',
    fullName: 'Chad',
    currency: 'XAF',
    countryCode: 'TD',
    accountLabel: 'account_number',
    currencyName: 'Frank CFA',
    currencySign: 'FCFA',
  },
  MG: {
    phonePrefix: '+261',
    fullName: 'Madagascar',
    currency: 'MGA',
    countryCode: 'MG',
    accountLabel: 'account_number',
    currencyName: 'Ariary',
    currencySign: 'Ar.',
  },
  LR: {
    phonePrefix: '+231',
    fullName: 'Liberia',
    currency: 'LRD',
    countryCode: 'LR',
    accountLabel: 'account_number',
    currencyName: 'Linden Dollar',
    currencySign: 'L$',
  },
  TN: {
    phonePrefix: '+216',
    fullName: 'Tunisia',
    currency: 'TND',
    countryCode: 'TN',
    accountLabel: 'account_number',
    currencyName: 'Tunisian dinar',
    currencySign: 'DT',
  },
  LA: {
    phonePrefix: '+856',
    fullName: 'Laos',
    currency: 'LAK',
    countryCode: 'LA',
    accountLabel: 'account_number',
    currencyName: 'kip',
    currencySign: '₭',
  },
  GA: {
    phonePrefix: '+241',
    fullName: 'Gabon',
    currency: 'XAF',
    countryCode: 'GA',
    accountLabel: 'account_number',
    currencyName: 'Frank CFA',
    currencySign: 'FCFA',
  },
  ME: {
    phonePrefix: '+382',
    fullName: 'Montenegro',
    currencySign: '€',
    currency: 'EUR',
    countryCode: 'ME',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  NI: {
    phonePrefix: '+505',
    countryCode: 'NI',
    fullName: 'Nicaragua',
    currency: 'NIO',
    currencySign: 'C$',
    accountLabel: 'account_number',
    currencyName: 'Córdoba',
  },
  IM: {
    phonePrefix: '+44',
    countryCode: 'IM',
    fullName: 'Isle of Man',
    currency: 'GBR',
    currencySign: '£',
    accountLabel: 'account_number',
    currencyName: 'Pound',
  },
  DK: {
    phonePrefix: '+45', // +298 +299
    countryCode: 'DK',
    fullName: 'Denmark',
    currency: 'DKK',
    currencySign: 'kr.',
    accountLabel: 'account_number',
    currencyName: 'Danish krone',
  },
  MR: {
    phonePrefix: '+222',
    countryCode: 'MR',
    fullName: 'Mauritania',
    currency: 'MRU',
    currencySign: 'UM',
    accountLabel: 'account_number',
    currencyName: 'ouguiya',
  },
  BF: {
    phonePrefix: '+226',
    countryCode: 'BF',
    fullName: 'Burkina Faso',
    currency: 'XOF',
    currencySign: 'XOF',
    accountLabel: 'account_number',
    currencyName: 'CFA frank',
  },
  DJ: {
    phonePrefix: '+253',
    countryCode: 'DJ',
    fullName: 'Djibouti',
    currency: 'DJF',
    currencySign: 'DJF',
    accountLabel: 'account_number',
    currencyName: 'Djiboutian franc',
  },
  JE: {
    phonePrefix: '+44',
    countryCode: 'JE',
    fullName: 'Jersey',
    currency: 'GBR',
    currencySign: '£',
    accountLabel: 'account_number',
    currencyName: 'Pound',
  },
  MU: {
    phonePrefix: '+230',
    countryCode: 'MU',
    fullName: 'Mauritius',
    currency: 'MUR',
    currencySign: '₨',
    accountLabel: 'account_number',
    currencyName: 'Mauritian rupee',
  },
  KM: {
    phonePrefix: '+269',
    countryCode: 'KM',
    fullName: 'Comoros',
    currency: 'KMF',
    currencySign: 'CF',
    accountLabel: 'account_number',
    currencyName: 'Comorian franc',
  },
  MM: {
    phonePrefix: '+95',
    countryCode: 'MM',
    fullName: 'Myanmar',
    currency: 'MMK',
    currencySign: 'MMK',
    accountLabel: 'account_number',
    currencyName: 'Kyat',
  },
  NE: {
    phonePrefix: '+227',
    countryCode: 'NE',
    fullName: 'Niger',
    currency: 'XOF',
    currencySign: 'XOF',
    accountLabel: 'account_number',
    currencyName: 'CFA frank',
  },
  SR: {
    phonePrefix: '+597',
    countryCode: 'SR',
    fullName: 'Suriname',
    currency: 'SRD',
    currencySign: '$',
    accountLabel: 'account_number',
    currencyName: 'Surinamese dollar',
  },
  SC: {
    phonePrefix: '+248',
    countryCode: 'SC',
    fullName: 'Seychelles',
    currency: 'SCR',
    currencySign: 'SCR',
    accountLabel: 'account_number',
    currencyName: 'Seychellois rupee',
  },
  GG: {
    phonePrefix: '+44',
    countryCode: 'GG',
    fullName: 'Guernsey',
    currency: 'GBR',
    currencySign: '£',
    accountLabel: 'account_number',
    currencyName: 'Pound',
  },
  MC: {
    phonePrefix: '+377',
    countryCode: 'MC',
    fullName: 'Monaco',
    currency: 'EUR',
    currencySign: '€',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  MW: {
    phonePrefix: '+265',
    countryCode: 'MW',
    fullName: 'Malawi',
    currency: 'MWK',
    currencySign: 'MWK',
    accountLabel: 'account_number',
    currencyName: 'Malawi Kwacha',
  },
  DO: {
    phonePrefix: '+1809', // +1-809, +1-829, +1-849
    countryCode: 'DO',
    fullName: 'Dominican Republic',
    currency: 'DOP',
    currencySign: 'DOP',
    accountLabel: 'account_number',
    currencyName: 'Dominican peso',
  },
  AO: {
    phonePrefix: '+244',
    countryCode: 'AO',
    fullName: 'Republic of Angola',
    currency: 'AOA',
    currencySign: 'AOA',
    accountLabel: 'account_number',
    currencyName: 'Kwanza',
  },
  KV: {
    phonePrefix: '+383',
    fullName: 'Republic of Kosovo',
    countryCode: 'KV',
    currency: 'EUR',
    currencySign: '€',
    accountLabel: 'account_number',
    currencyName: 'Euro',
  },
  AN: {
    phonePrefix: '+599',
    fullName: 'Netherlands Antilles',
    countryCode: 'AN',
    currency: 'ANG',
    currencySign: 'ANG',
    accountLabel: 'account_number',
    currencyName: 'Antilles guilde',
  },
  SB: {
    phonePrefix: '+677',
    fullName: 'Solomon islands',
    countryCode: 'SB',
    currency: 'SBD',
    currencySign: 'SI$',
    accountLabel: 'account_number',
    currencyName: 'SI dollar',
  },
  KR: {
    phonePrefix: "+82",
    fullName: "South Korea",
    countryCode: "KR",
    currency: "KRW",
    currencySign: "￦",
    currencyName: "Korean won",
  },
  MQ: {
    phonePrefix: "+596",
    fullName: "Martinique",
    countryCode: "MQ",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  TL: {
    phonePrefix: "+670",
    fullName: "Timor-Leste",
    countryCode: "TL",
    currency: "USD",
    currencySign: "$",
    currencyName: "US Dollar",
  },
  PR: {
    phonePrefix: "+1",
    fullName: "Puerto Rico",
    countryCode: "PR",
    currency: "USD",
    currencySign: "$",
    currencyName: "US Dollar",
  },
  PG: {
    phonePrefix: "+675",
    fullName: "Papua New Guinea",
    countryCode: "PG",
    currency: "PGK",
    currencySign: "PGK",
    currencyName: "Papua NG kina",
  },
  KW: {
    phonePrefix: "+965",
    fullName: "Kuwait",
    countryCode: "KW",
    currency: "KWD",
    currencySign: "KD",
    currencyName: "Kuwaiti dinar",
  },
  MO: {
    phonePrefix: "+853",
    fullName: "Macau",
    countryCode: "MO",
    currency: "MOP",
    currencySign: "MOP",
    currencyName: "Macanese Pataca",
  },
  MK: {
    phonePrefix: "+389",
    fullName: "Macedonia",
    countryCode: "MK",
    currency: "MKD",
    currencySign: "DEN",
    currencyName: "Macedonian Denar",
  },
  MV: {
    phonePrefix: "+960",
    fullName: "Maldives",
    countryCode: "MV",
    currency: "MVR",
    currencySign: "MVR",
    currencyName: "Maldivian Rufiyaa",
  },
  NA: {
    phonePrefix: "+264",
    fullName: "Namibia",
    countryCode: "NA",
    currency: "NAD",
    currencySign: "NAD",
    currencyName: "Namibian Dollar",
  },
  MS: {
    phonePrefix: "+1664",
    fullName: "Montserrat",
    countryCode: "MS",
    currency: "XCD",
    currencySign: "EC$",
    currencyName: "East Caribbean Dollar",
  },
  NR: {
    phonePrefix: "+674",
    fullName: "Nauru",
    countryCode: "NR",
    currency: "AUD",
    currencySign: "AUD",
    currencyName: "Australian Dollar",
  },
  OM: {
    phonePrefix: "+968",
    fullName: "Oman",
    countryCode: "OM",
    currency: "OMR",
    currencySign: "OMR",
    currencyName: "Omani Rial",
  },
  PF: {
    phonePrefix: "+689",
    fullName: "French Polynesia",
    countryCode: "PF",
    currency: "XPF",
    currencySign: "XPF",
    currencyName: "CFP franc",
  },
  AD: {
    phonePrefix: "+376",
    fullName: "Andorra",
    countryCode: "AD",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  QA: {
    phonePrefix: "+974",
    fullName: "Qatar",
    countryCode: "QA",
    currencySign: "QAR",
    currency: "QAR",
    currencyName: "Qatari Riyal",
  },
  RE: {
    phonePrefix: "+262",
    fullName: "Réunion",
    countryCode: "RE",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  KN: {
    phonePrefix: "+1869",
    fullName: "Saint Kitts and Nevis",
    countryCode: "KN",
    currency: "XCD",
    currencySign: "EC$",
    currencyName: "East Caribbean Dollar",
  },
  VC: {
    phonePrefix: "+1784",
    fullName: "Saint Vincent and the Grenadines",
    countryCode: "VC",
    currency: "XCD",
    currencySign: "EC$",
    currencyName: "East Caribbean Dollar",
  },
  LC: {
    phonePrefix: "+1758",
    fullName: "Saint Lucia",
    countryCode: "LC",
    currency: "XCD",
    currencySign: "EC$",
    currencyName: "East Caribbean Dollar",
  },
  WS: {
    phonePrefix: "+685",
    fullName: "Samoa",
    countryCode: "WS",
    currency: "WST",
    currencySign: "WST",
    currencyName: "Samoan Tala",
  },
  CV: {
    phonePrefix: "+238",
    fullName: "Cape Verde",
    countryCode: "CV",
    currency: "CVE",
    currencySign: "CVE",
    currencyName: "Cape Verdean Escudo",
  },
  BA: {
    phonePrefix: "+387",
    fullName: "Bosnia and Herzegovina",
    countryCode: "BA",
    currency: "BAM",
    currencySign: "BAM",
    currencyName: "Convertible mark",
  },
  MF: {
    phonePrefix: "+1590",
    fullName: "Saint Martin",
    countryCode: "MF",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  TO: {
    phonePrefix: "+676",
    fullName: "Tonga",
    countryCode: "TO",
    currencySign: "TOP",
    currency: "TOP",
    currencyName: "Tongan Paʻanga",
  },
  TT: {
    phonePrefix: "+1868",
    fullName: "Trinidad and Tobago",
    countryCode: "TT",
    currencySign: "TTD",
    currency: "TTD",
    currencyName: "TTrinidad & Tobago Dollar",
  },
  TC: {
    phonePrefix: "+1649",
    fullName: "Turks and Caicos Islands",
    countryCode: "TC",
    currency: "USD",
    currencySign: "$",
    currencyName: "US Dollar",
  },
  SM: {
    phonePrefix: "+378",
    fullName: "San Marino",
    countryCode: "SM",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  BH: {
    phonePrefix: "+973",
    fullName: "Bahrain",
    countryCode: "BH",
    currencySign: "BHD",
    currency: "BHD",
    currencyName: "Bahraini Dinar",
  },
  BW: {
    phonePrefix: "+267",
    fullName: "Botswana",
    countryCode: "BW",
    currencySign: "BWP",
    currency: "BWP",
    currencyName: "Botswanan Pula",
  },
  CF: {
    phonePrefix: "+236",
    fullName: "Central African Republic",
    countryCode: "CF",
    currencySign: "XAF",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  BL: {
    phonePrefix: "+590",
    fullName: "Saint Barthélemy",
    countryCode: "BL",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  BM: {
    phonePrefix: "+1441",
    fullName: "Bermuda",
    countryCode: "BM",
    currencySign: "BMD",
    currency: "BMD",
    currencyName: "Bermudan Dollar",
  },
  BS: {
    phonePrefix: "+1242",
    fullName: "Bahamas",
    countryCode: "BS",
    currencySign: "BSD",
    currency: "BSD",
    currencyName: "Bahamian Dollar",
  },
  BT: {
    phonePrefix: "+975",
    fullName: "Bhutan",
    countryCode: "BT",
    currencySign: "BTN",
    currency: "BTN",
    currencyName: "Bhutanese ngultrum",
  },
  TV: {
    phonePrefix: "+688",
    fullName: "Tuvalu",
    countryCode: "TV",
    currency: "AUD",
    currencySign: "AUD",
    currencyName: "Australian Dollar",
  },
  BZ: {
    phonePrefix: "+501",
    fullName: "Belize",
    countryCode: "BZ",
    currency: "BZD",
    currencySign: "BZD",
    currencyName: "Belize Dollar",
  },
  GL: {
    phonePrefix: "+299",
    fullName: "Greenland",
    countryCode: "GL",
    currency: "DKK",
    currencySign: "kr.",
    currencyName: "Danish krone",
  },
  FM: {
    phonePrefix: "+691",
    fullName: "Micronesia",
    countryCode: "FM",
    currency: "USD",
    currencySign: "$",
    currencyName: "US Dollar",
  },
  VU: {
    phonePrefix: "+678",
    fullName: "Vanuatu",
    countryCode: "VU",
    currency: "VUV",
    currencySign: "VUV",
    currencyName: "Vanuatu Vatu",
  },
  VA: {
    phonePrefix: "+379",
    fullName: "Vatican City",
    countryCode: "VA",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  YT: {
    phonePrefix: "+262",
    fullName: "Mayotte",
    countryCode: "YT",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  LY: {
    phonePrefix: "+218",
    fullName: "Libya",
    countryCode: "LY",
    currencySign: "LYD",
    currency: "LYD",
    currencyName: "Libyan Dinar",
  },
  GQ: {
    phonePrefix: "+240",
    fullName: "Equatorial Guinea",
    countryCode: "GQ",
    currencySign: "XAF",
    currency: "XAF",
    currencyName: "Central African CFA franc",
  },
  ST: {
    phonePrefix: "+239",
    fullName: "São Tomé and Príncipe",
    countryCode: "ST",
    currencySign: "STD",
    currency: "STD",
    currencyName: "dobra",
  },
  BN: {
    phonePrefix: "+673",
    fullName: "Brunei",
    countryCode: "BN",
    currencySign: "BND",
    currency: "BND",
    currencyName: "Brunei Dollar",
  },
  GP: {
    phonePrefix: "+590",
    fullName: "Guadeloupe",
    countryCode: "GP",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  LS: {
    phonePrefix: "+266",
    fullName: "Lesotho",
    countryCode: "LS",
    currencySign: "LSL",
    currency: "LSL",
    currencyName: "Lesotho Loti",
  },
  MH: {
    phonePrefix: "+692",
    fullName: "Marshall Islands",
    countryCode: "MH",
    currency: "USD",
    currencySign: "$",
    currencyName: "US Dollar",
  },
  GU: {
    phonePrefix: "+1671",
    fullName: "Guam",
    countryCode: "GU",
    currency: "USD",
    currencySign: "$",
    currencyName: "US Dollar",
  },
  SZ: {
    phonePrefix: "+268",
    fullName: "Eswatini",
    countryCode: "SZ",
    currency: "SZL",
    currencySign: "SZL",
    currencyName: "Swazi Lilangeni",
  },
  NC: {
    phonePrefix: "+687",
    fullName: "New Caledonia",
    countryCode: "NC",
    currency: "XPF",
    currencySign: "XPF",
    currencyName: "CFP franc",
  },
  AX: {
    phonePrefix: "+358",
    fullName: "Åland Islands",
    countryCode: "AX",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
  PM: {
    phonePrefix: "+508",
    fullName: "Saint Pierre and Miquelon",
    countryCode: "PM",
    currencySign: "€",
    currency: "EUR",
    currencyName: "Euro",
  },
}

class CountryService {
  getPhonePrefix(countryCode: string): string {
    if (!countryCode) {
      return ''
    }

    return _.get(CountryData, [countryCode, 'phonePrefix'])
  }

  getFullName(countryCode: string): string {
    if (!countryCode) {
      return ''
    }

    return _.get(CountryData, [countryCode, 'fullName'], '')
  }

  getCurrencySymbol(countryCode: string): string {
    if (!countryCode) {
      return ''
    }

    return _.get(CountryData, [countryCode, 'currencySign'])
  }

  getCurrencyCode(countryCode: string): string {
    if (!countryCode) {
      return ''
    }

    return _.get(CountryData[countryCode], 'currency')
  }

  getCountryCode(countryCode: string): string {
    if (!countryCode) {
      return ''
    }

    return CountryData[countryCode].countryCode
  }

  getFlagClass(countryCode: string): string {
    if (!countryCode) {
      return ''
    }

    return _.get(CountryData[countryCode], 'flagClass', '')
  }

  getCountryCodeByCurrencyCode(currencyCode: string) {
    for (const country in CountryData) {
      if (_.get(CountryData[country], 'currency') === currencyCode) {
        return currencyCode === 'EUR' ? 'EU' : CountryData[country].countryCode
      }
    }
  }

  getCountryCodeByFullName(fullName: string) {
    for (const country in CountryData) {
      if (_.get(CountryData[country], 'fullName') === fullName) {
        return CountryData[country].countryCode
      }
    }
  }

  getCurrencySymbolByCurrencyCode(currencyCode: string) {
    if (!currencyCode) {
      return ''
    }
    for (const country in CountryData) {
      if (_.get(CountryData[country], 'currency') === currencyCode) {
        return _.get(CountryData, [country, 'currencySign'])
      }
    }
  }

  getCurrencyNameByCurrencyCode(currencyCode: string) {
    for (const country in CountryData) {
      if (_.get(CountryData[country], 'currency') === currencyCode) {
        return _.get(CountryData, [country, 'currencyName'])
      }
    }
    return ''
  }

  getCurrencyCodeByCurrencyName(currencyName: string) {
    for (const country in CountryData) {
      if (_.get(CountryData[country], 'currencyName') === currencyName) {
        return _.get(CountryData, [country, 'currency'])
      }
    }
    return ''
  }

  gethonePrefixByCurrencyName(currencyName: string) {
    for (const country in CountryData) {
      if (_.get(CountryData[country], 'currencyName') === currencyName) {
        return _.get(CountryData, [country, 'currency'])
      }
    }
  }

  getRecipientAccountLabel(countryCode: string) {
    if (!countryCode) return ''
    return _.get(CountryData[countryCode], 'accountLabel')
  }

  getAllCountries() {
    return Object.keys(CountryData)
      .map((country) => this.getFullName(country))
      .sort()
      .map((fullName) => this.getCountryCodeByFullName(fullName))
  }
}

const countryService = new CountryService()

export default countryService
