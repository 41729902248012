import { call, takeEvery, take, put, select, fork, all, delay, cancelled, ForkEffect } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { get, chain } from 'lodash'
import moment from 'moment'
import { actionsHandler } from 'services/apiServices/api'
import {
  getTransactions,
  walletHistoryReport,
  getTransactionDocuments,
  downloadTransactionDocument,
  rejectTransaction,
  uploadTransactionDocument,
  walletAnalyticsReport,
} from 'services/apiServices/transaction.service'
import {
  IFilteredPayload,
  TRANSACTION_STATUSES,
  ITransactionDetail,
  IWalletHistoryPayload,
  IFavoriteTransaction,
  IAnalyticaPayload,
  IOperation,
  TAnalyticaByGroup,
  TCurrencies,
  TAnalytica,
  IAnalyticResponse,
  IOperationDetail,
} from 'model/transaction.model'
import { UPLOAD_TYPES } from 'model/user.model'
import { PAYOUT_TYPES } from 'model/transfer.model'
import { RootState } from '../store'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'
import {
  getTransactionsActions,
  getFilteredActions,
  getCurrentTransactionActions,
  setTransactionDocuments,
  rejectTransactionActions,
  uploadTransactionDocumentActions,
  getWalletHistoryActions,
  getRecentOperations,
  getFavoriteTransactionActions,
  addFavoriteTransactionActions,
  deleteFavoriteTransactionActions,
  getMonthAnalyticaActions,
  getCurrentOperationActions,
} from 'redux/reducers/transaction.reducer'
import {
  makeTransactionSuccess,
  validateTransactionSuccess,
  confirmTransactionSuccess
} from 'redux/reducers/transfer.reducer'
import { clearUploadFiles } from 'redux/reducers/user.reducer'
import { openToast, closeSpinner } from 'redux/reducers/ui.reducer'

const organizationId = process.env.REACT_APP_ORGANIZATION_ID

export function* getLastTransactions() {
  try {
    const walletPayoutTypes = [
      'PAYMENT_FROM_WALLET',
      'WALLET_TO_WALLET',
      'PAYMENT_FROM_CARD',
      'WALLET_EXCHANGE',
      'CASH_TO_ACCOUNT',
      'PAYOUT_TO_WALLET',
      'TARIFF',
    ];
    const defaultPayoutTypes = Object.keys(PAYOUT_TYPES).filter(key => !walletPayoutTypes.includes(key))

    let query = 'size=20&page=0&transactionStatuses=PRE_TXN,FS_CHARGED,FS_IN_PROCESS,PROCESSING,PENDING_REVIEW,COMPLIANCE_REVIEW,COMPLETE_SUCCESS,CANCELLED,PENDING_CANCELLATION,FAIL'
    query += `&payoutTypes=${defaultPayoutTypes.join()}`

    const filterableStatuses = ['REVERSED', 'COMPLIANCE_FAILED', 'FAIL_FS_CHARGED', 'FS_DEINED', 'RISK_APPROVED', 'PENDING_FS', 'REJECTED']
    const { data } = yield call(getTransactions, { query })

    const filteredTransfers = chain(data.transactions)
      .filter((item) => !~filterableStatuses.indexOf(item.transactionStatus) && !(item.transactionStatus === 'PRE_TXN' && item.fundingSource === 'CREDIT_CARD'))
      .orderBy('date', 'desc')
      .value()
    yield put(
      getTransactionsActions[SUCCESS]({
        transactions: [...filteredTransfers].slice(0, 10),
      })
    )
  } catch (err) {
    yield put(getTransactionsActions[FAIL]())
  }
}

export function* getFilteredTransactions({ payload }: PayloadAction<IFilteredPayload>) {
  try {
    const { startDate, endDate, statuses, countries, payouts, recipientName, page: payloadPage } = payload
    const { list, page, totalPages: pagesQty } = yield select(({ transactions }: RootState) => transactions.transactionHistory)

    if (+payloadPage !== 0 && (+payloadPage === +page || (pagesQty && +payloadPage + 1 > +pagesQty))) {
      yield put(getFilteredActions[FAIL]())
      return
    }

    const walletPayoutTypes = [
      'PAYMENT_FROM_WALLET',
      'WALLET_TO_WALLET',
      'PAYMENT_FROM_CARD',
      'WALLET_EXCHANGE',
      'PAYOUT_TO_WALLET',
      'TARIFF',
    ];

    const defaultPayoutTypes = Object.keys(PAYOUT_TYPES).filter(key => !walletPayoutTypes.includes(key));
    const defaultStatuses = 'PRE_TXN,FS_CHARGED,FS_IN_PROCESS,PROCESSING,PENDING_REVIEW,COMPLIANCE_REVIEW,COMPLIANCE_LOCKED,COMPLETE_SUCCESS,CANCELLED,PENDING_CANCELLATION,FAIL';
    const transactionStatuses = !statuses.length
      ? defaultStatuses
      : statuses.reduce((acc: string[], status) => [...acc, ...TRANSACTION_STATUSES[status].values], []).join()

    let query = `size=20&page=${payloadPage}&transactionStatuses=${transactionStatuses}`
    query += `&payoutTypes=${payouts.length > 0 ? payouts.join() : defaultPayoutTypes.join()}`

    if (recipientName) {
      query += `&recipientName=${recipientName}`;
    }
    if (countries.length) {
      query += `&destinationCountries=${countries.join()}`
    }
    if (!!startDate) {
      query += `&startDate=${startDate + '%2000:00:00.0'}`
    }
    if (!!endDate) {
      query += `&endDate=${endDate + '%2023:59:59.0'}`
    }
    if (!!startDate && !endDate) {
      query += `&endDate=${moment().format('YYYY-MM-DD') + '%2023:59:59.0'}`
    }

    const filterableStatuses = [
      'REVERSED',
      'COMPLIANCE_FAILED',
      'FAIL_FS_CHARGED',
      'FS_DEINED',
      'RISK_APPROVED',
      'PENDING_FS',
      'REJECTED'
    ];
    const { data: { transactions, totalPages, totalCount } } = yield call(getTransactions, { query })

    const filteredTransfers = chain(transactions)
      .filter((item) => item.payoutType.includes('WALLET_DEPOSIT_')
        ? ['PRE_TXN', 'COMPLIANCE_REVIEW', 'COMPLIANCE_LOCKED', 'CANCELLED'].includes(item.transactionStatus)
        : (!~filterableStatuses.indexOf(item.transactionStatus)
          && !(item.transactionStatus === 'PRE_TXN' && item.fundingSource === 'CREDIT_CARD')
          && item.operatorId !== '62617689-8ce2-468d-a303-26c1d4d47d58'))
      .orderBy('date', 'desc')
      .value()
    yield put(
      getFilteredActions[SUCCESS]({
        list: +payloadPage === 0 ? filteredTransfers : [...list, ...filteredTransfers],
        totalCount,
        totalPages,
        page: payloadPage,
      })
    )
    if (+payloadPage === 0) {
      yield put(getCurrentTransactionActions[SUCCESS](null))
    }
  } catch (err) {
    yield put(getFilteredActions[FAIL]())
  }
}

export function* getWalletHistorySaga({ payload }: PayloadAction<IWalletHistoryPayload>) {
  try {
    const {
      list,
      isFinished,
      pageNum: currentPage,
      sessionKey: currentSessionKey,
      pageKey,
    } = yield select(({ transactions }) => transactions.walletHistory);
    const { startDate, endDate, pageNum, recent, ...rest } = payload;

    if (+pageNum > 0 && (isFinished || +pageNum === +currentPage)) {
      yield put(getWalletHistoryActions[FAIL]());
      return;
    }

    const {
      data: {
        walletId,
        cardId,
        status,
        message,
        sessionKey,
        pageKey_createdDateTo: pk,
        reportData,
      }
    } = yield call(walletHistoryReport, {
      pageNum,
      sortField: 'DATE',
      sortDirection: 'DESC',
      sessionKey: currentSessionKey || null,
      pageKey_createdDateTo: pageNum === 0 ? null : pageKey || null,
      pcRegTimeFrom: startDate
        ? moment.utc(startDate + ' 00:00:00').format()
        : null,
      pcRegTimeTo: endDate
        ? moment.utc(endDate + ' 23:59:59').format()
        : !startDate ? null : moment.utc(moment().format('YYYY-MM-DD') + ' 23:59:59').format(),
      ...rest,
    })

    if (status !== 'SUCCESS') {
      throw new Error(message)
    }

    const {
      walletId: currentWallet,
      cardId: currentCard,
    } = yield select(({ transactions }) => transactions.walletHistory);

    if (!!currentWallet && (+currentWallet !== +walletId || +currentCard != +cardId)) {
      return;
    }

    if (recent) {
      yield put(getRecentOperations(reportData))
      yield put(
        getWalletHistoryActions[SUCCESS]({
          list: reportData,
          pageNum,
          sessionKey,
          pageKey: pk,
          isFinished: false,
          walletId,
          cardId,
        })
      );
    } else {
      yield put(
        getWalletHistoryActions[SUCCESS]({
          list: pageNum === 0 ? reportData : [...list].concat(reportData),
          pageNum,
          sessionKey,
          pageKey: pk,
          isFinished: reportData.length < rest.pageSize,
          walletId,
          cardId,
        })
      );
    }

    return true;
  } catch (err) {
    yield put(getWalletHistoryActions[FAIL]());
  }
}

export function* getTransactionDocumentsSaga({ payload }: PayloadAction<ITransactionDetail>) {
  if (!payload) {
    return
  }
  try {
    const {
      data: { userDocumentDetails },
    } = yield call(getTransactionDocuments, { transactionId: payload.transactionId })
    const result = []

    for (const document of userDocumentDetails) {
      const base64Image: string = yield downloadTransactionDocument(document.documentId)
      const uri = `data:image/${document.fileExt};base64,${base64Image}`
      result.push({
        uri,
        documentType: document.documentType,
        fileName: document.documentType + '.' + document.fileExt,
      })
    }
    yield put(setTransactionDocuments(result))

    if ((downloadTransactionDocument.cache as any).size > 10 && (downloadTransactionDocument.cache as any).clear) {
      ;(downloadTransactionDocument.cache as any).clear()
    }
  } catch (err) {
    console.log(err)
  }
}

export function* uploadTransactionDocumentSaga() {
  try {
    const { upload } = yield select((state) => state.user)
    const { currentTransaction } = yield select((state) => state.transactions)

    const { image, name, mimeType } = upload[UPLOAD_TYPES.INVOICE]
    const formData = new FormData()
    const blob: Blob = yield fetch(image).then((res) => res.blob())
    const fileForUpload = new File([blob], name, { type: mimeType })
    formData.append('file', fileForUpload)
    formData.append('transactionId', currentTransaction.transactionId)
    formData.append('description', UPLOAD_TYPES.INVOICE)

    const {
      data: { status, message },
    } = yield call(uploadTransactionDocument, formData)

    if (status && (status as string).toUpperCase() === 'SUCCESS') {
      if (currentTransaction.payoutType === 'WALLET_DEPOSIT_BANK' && (!currentTransaction.documents || !currentTransaction.documents.length)) {
        yield put(getCurrentTransactionActions[SUCCESS](null))
      } else {
        yield put(
          setTransactionDocuments([
            {
              uri: image,
              documentType: 'INVOICE',
              fileName: name || 'invoice',
            },
          ])
        )
        yield put(
          openToast({
            type: 'success',
            toastMessage: 'transaction:document_uploaded',
          })
        )
      }
      yield put(uploadTransactionDocumentActions[SUCCESS]())
      yield put(clearUploadFiles(UPLOAD_TYPES.INVOICE))
    } else {
      yield put(uploadTransactionDocumentActions[FAIL]())
      yield put(
        openToast({
          type: 'error',
          toastMessage: message || 'transaction:something_went_wrong_please_try_again_later',
        })
      )
    }
  } catch (err) {
    yield put(uploadTransactionDocumentActions[FAIL]())
    yield put(
      openToast({
        type: 'error',
        toastMessage: err.message || 'transaction:something_went_wrong_please_try_again_later',
      })
    )
  }
}

export function* clearCurrentTransaction() {
  const { currentTransaction } = yield select((state) => state.transactions)
  if (!!currentTransaction) {
    yield put(getCurrentTransactionActions[SUCCESS](null))
  }
}

export function* rejectTransactionSaga() {
  try {
    let transactionId = ''
    const { currentTransaction } = yield select(({ transactions }) => transactions)
    const { transactionMeta } = yield select(({ transfer }) => transfer)
    if (!currentTransaction) {
      transactionId = transactionMeta.transactionId
    } else {
      transactionId = currentTransaction.transactionId
    }
    if (!!transactionId) {
      yield call(rejectTransaction, { transactionId })
      const updatedTransaction = { ...currentTransaction, transactionStatus: 'REJECTED' }
      yield put(rejectTransactionActions[SUCCESS](updatedTransaction))
      yield put(closeSpinner())
      const { navigation } = yield select(({ ui }) => ui)
      if (!currentTransaction) {
        navigation.navigate('/dashboard')
      } else {
        navigation.navigate(-1)
      }
    } else {
      yield put(rejectTransactionActions[FAIL]())
    }
  } catch (err) {
    yield put(rejectTransactionActions[FAIL]())
  }
}

export function* getAnalyticsReportSaga({ payload }: PayloadAction<IAnalyticaPayload>) {
  try {
    const { report } = yield select(({ transactions }) => transactions.analytics);

    const { data: { reportData, sessionKey: responseKey } } = yield call(walletAnalyticsReport, payload);

    const result = { ...report };
    const isSpending = payload.direction === 'CREDIT';

    reportData.forEach(({ year, month, analyticData }: IAnalyticResponse) => {
      const keyMonth = `${month}_${year}`;
      const modeData: TAnalyticaByGroup = {};
      const total = { ILS: 0, USD: 0, EUR: 0 };

      analyticData.forEach(({ currency, amountSum: monthCurrencySum, mccGroupData }) => {
        total[currency as TCurrencies] = monthCurrencySum;
        mccGroupData.forEach(({ mccGroupName, amountSum: categorySum, reportData }) => {
          const reversal: IOperation[] = [];
          let isReversal = false;
          let reverseSum = 0;
          let list = reportData;
          let sum = categorySum;
          if (isSpending) {
            list = reportData.filter(operation => {
              if (operation.isReversal) {
                reversal.push(operation);
                reverseSum += +operation.accAmount || +operation.trnAmount;
                sum -= +operation.accAmount || +operation.trnAmount;
                return false;
              }
              return true;
            })
          } else {
            const item = list[0];
            const { messageCode = '' } = !item.comment ? {} : JSON.parse(item.comment);
            if (messageCode === 'REVERT_TO_WALLET') {
              isReversal = true;
            }
          }

          if (!modeData[mccGroupName]) {
            modeData[mccGroupName] = {
              ...(modeData[mccGroupName] || {}),
              [currency as TCurrencies]: {
                name: mccGroupName,
                sum,
                list,
                isReversal,
              }
            };
          } else if (!modeData[mccGroupName][currency as TCurrencies]) {
            modeData[mccGroupName][currency as TCurrencies] = {
              name: mccGroupName,
              sum,
              list,
              isReversal,
            };
          }

          if (isSpending && reverseSum > 0) {
            total[currency as TCurrencies] = total[currency as TCurrencies] - reverseSum;
            result[keyMonth].totalIncomings[currency] = result[keyMonth].totalIncomings[currency] + reverseSum;
            let categoryExist = false;
            result[keyMonth].incomings = report[keyMonth].incomings.map((income: TAnalytica) => {
              categoryExist = income.ILS?.name === mccGroupName || income.USD?.name === mccGroupName || income.EUR?.name === mccGroupName;
              if (income[currency as TCurrencies] && income[currency as TCurrencies].name === mccGroupName) {
                return {
                  ...income,
                  [currency]: {
                    name: mccGroupName,
                    sum: income[currency as TCurrencies].sum + reverseSum,
                    list: [...income[currency as TCurrencies].list, ...reversal],
                    isReversal: true,
                  }
                }
              } else if (categoryExist) {
                return {
                  ...income,
                  [currency]: {
                    name: mccGroupName,
                    sum: reverseSum,
                    list: [...reversal],
                    isReversal: true,
                  }
                };
              } else {
                return { ...income };
              }
            });
            if (!categoryExist) {
              result[keyMonth].incomings = [...report[keyMonth].incomings, {
                [currency]: {
                  name: mccGroupName,
                  sum: reverseSum,
                  list: [...reversal],
                  isReversal: true,
                }
              }]
            }
          }
        });
      });

      result[keyMonth] = {
        ...result[keyMonth],
        [isSpending ? 'spendings' : 'incomings']: Object.values(modeData),
        [isSpending ? 'totalSpendings' : 'totalIncomings']: total,
      };
    });

    yield put(
      getMonthAnalyticaActions[SUCCESS]({
        report: result,
        sessionKey: responseKey,
        walletId: payload.walletId,
        cardId: payload?.cardId || '',
      })
    );

    if (payload.direction === 'DEBIT') {
      yield put(
        getMonthAnalyticaActions[REQUEST]({
          ...payload,
          sessionKey: responseKey,
          direction: 'CREDIT',
        })
      );
    }

    return true;
  } catch (err) {
    yield put(getMonthAnalyticaActions[FAIL]());
  }
}

export function* updateFavoriteSaga() {
  yield put(getFavoriteTransactionActions[REQUEST]())
}

const getCurrentTransactionFork = fork(actionsHandler, {
  actions: getCurrentTransactionActions,
  method: 'POST',
  paramsGetter: (transactionId: string) => ({ data: { queryType: 'ID', queryCode: transactionId } }),
  responseParser: ({ data }: { data: { transactionDetails: ITransactionDetail } }) => data.transactionDetails,
  url: '/transactionNew/queryByCode',
})

const getCurrentOperationFork = fork(actionsHandler, {
  actions: getCurrentOperationActions,
  method: 'POST',
  paramsGetter: (operation: IOperation) => ({ data: { walletLedgerId: operation.walletLedgerId } }),
  responseParser: (response: { data: { detailsData: Partial<IOperationDetail> } }, payload: IOperation) => ({
    ...response.data.detailsData,
    ...payload,
  }),
  url: '/mobile/report/walletOnlineLedgerReport/details',
})

const getFavoriteTransactionFork = fork(actionsHandler, {
  actions: getFavoriteTransactionActions,
  method: 'GET',
  responseParser: ({ data }: { data: IFavoriteTransaction[] }) => data.map(trx => ({ ...trx, ...trx.priceResponse })).reverse(),
  url: '/users/transactions/favorite',
})

const addFavoriteTransactionFork = fork(actionsHandler, {
  actions: addFavoriteTransactionActions,
  method: 'POST',
  paramsGetter: (transactionId: string) => ({ data: { transactionId: [transactionId] } }),
  url: '/users/transactions/favorite',
})

const deleteFavoriteTransactionFork = fork(actionsHandler, {
  actions: deleteFavoriteTransactionActions,
  method: 'DELETE',
  paramsGetter: (transactionId: string) => ({ data: { transactionId: [transactionId] } }),
  url: '/users/transactions/favorite',
})

export default function* rootSaga() {
  yield all<ForkEffect>([
    yield takeEvery([
      getTransactionsActions[REQUEST].toString(),
      makeTransactionSuccess.toString(),
      validateTransactionSuccess.toString(),
      confirmTransactionSuccess.toString(),
      rejectTransactionActions[SUCCESS].toString(),
    ], getLastTransactions),
    yield takeEvery(getFilteredActions[REQUEST].toString(), getFilteredTransactions),
    yield takeEvery(getWalletHistoryActions[REQUEST].toString(), getWalletHistorySaga),
    yield takeEvery(getCurrentTransactionActions[SUCCESS].toString(), getTransactionDocumentsSaga),
    yield takeEvery(rejectTransactionActions[REQUEST].toString(), rejectTransactionSaga),
    yield takeEvery(uploadTransactionDocumentActions[REQUEST].toString(), uploadTransactionDocumentSaga),
    yield takeEvery(getMonthAnalyticaActions[REQUEST].toString(), getAnalyticsReportSaga),
    yield takeEvery([
      addFavoriteTransactionActions[SUCCESS].toString(),
      deleteFavoriteTransactionActions[SUCCESS].toString(),
    ], updateFavoriteSaga),
    getCurrentTransactionFork,
    getCurrentOperationFork,
    getFavoriteTransactionFork,
    addFavoriteTransactionFork,
    deleteFavoriteTransactionFork,
  ])
}
