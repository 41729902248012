import { responsiveFontSizes, createTheme, Theme } from '@mui/material/styles'
import AbradeRegular from 'assets/fonts/Abrade-Regular.ttf'
import AbradeBlack from 'assets/fonts/Abrade-Black.ttf'
import AbradeBold from 'assets/fonts/Abrade-Bold.ttf'
import AbradeLight from 'assets/fonts/Abrade-Light.ttf'
import AbradeMedium from 'assets/fonts/Abrade-Medium.ttf'
import AbradeSemiBold from 'assets/fonts/Abrade-SemiBold.ttf'
import AbradeExtraBold from 'assets/fonts/Abrade-ExtraBold.ttf'


const abradeRegular = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url(${AbradeRegular}) format('truetype')`,
}

const abradeLight = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${AbradeLight}) format('truetype')`,
}

const abradeMedium = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${AbradeMedium}) format('truetype')`,
}

const abradeSemiBold = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${AbradeSemiBold}) format('truetype')`,
}

const abradeExtraBold = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `url(${AbradeExtraBold}) format('truetype')`,
}

const abradeBold = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url(${AbradeBold}) format('truetype')`,
}

const abradeBlack = {
  fontFamily: 'Abrade',
  fontStyle: 'normal',
  fontWeight: 900,
  src: `url(${AbradeBlack}) format('truetype')`,
}

const createAppTheme = ({ language }: { language: string }) => {
  const isHebrew: boolean = language === 'he'

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1360,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        main: '#33A7FF',
        dark: '#005799',
        light: '#0090FF',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#69D68C',
        dark: '#0F993C',
        light: '#DCF5E4',
        contrastText: '#FFF',
      },
      info: {
        main: '#66BDFF',
        dark: '#B9E0FF',
        light: '#D9EEFF',
        contrastText: '#FFF',
      },
      warning: {
        main: '#ECE2F9',
        dark: '#6E4D99',
        light: '#ECE2F9',
        contrastText: '#FFF',
      },
      success: {
        main: '#3AC066',
        dark: '#0A6628',
        light: '#DCF5E4',
        contrastText: '#FFF',
      },
      error: {
        main: '#EB5757',
        dark: '#B30707',
        light: '#FFE9E9',
        contrastText: '#FFF',
      },
      grey: {
        50: '#F5F8FA',
        100: '#EEF3F7',
        200: '#D0D4D7', //
        300: '#ACB1B5',
        400: '#777C80',
        500: '#45494D', //
        600: '#252627',
        700: '#45494D', // -
        800: '#191B1D',
        900: '#556169',

        A100: '#191B1D',
        A200: '#E1E7EC',
        A400: '#D0D4D7',
        A700: '#D9EEFF',
      },
      designGrey: {
        100: '#F0F5FA',
        200: '#CFDAE4',
        300: '#ACBCC9',
        400: '#76858F',
        500: '#5F6D76',
        600: '#BCD3E4',
        900: '#3D4145',
      },
      text: {
        primary: '#252627',
        secondary: '#76858F',
        disabled: '#ACB1B5',
      },
      background: {
        default: '#fff',
        paper: '#F5F8FA',
      },
      euro: {
        main: '#EDE6F5',
        dark: '#6E4D99',
        light: '#B9A0DB',
        contrastText: '#6E4D99',
      },
      dollar: {
        main: '#DCF5E4',
        dark: '#0F993C',
        light: '#7FD39A',
        contrastText: '#0F993C',
      },
      shekel: {
        main: '#D9EEFF',
        dark: '#0090FF',
        light: '#66BDFF',
        contrastText: '#0090FF',
      },
      orange: {
        main: '#E28E00',
        dark: '#E28E00',
        light: '#FFEED1',
        contrastText: '#E28E00',
      },
      divider: '#EEF3F7',
    },
    typography: {
      fontFamily: 'Abrade',
      htmlFontSize: 16,
      fontSize: 14,
      button: {
        fontSize: '0.9375rem',
        lineHeight: 1.2,
        textAlign: "center",
        textTransform: 'none',
        fontWeight: 700,
      },
      h1: {
        fontSize: '3.875rem !important', // 62
        lineHeight: 1,
        fontWeight: 'inherit',
      },
      h2: {
        fontSize: '2.25rem !important', // 36
        lineHeight: 1,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      title2: {
        fontSize: '2rem', // 32
        lineHeight: 1.2,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      title: {
        fontSize: '1.75rem', // 28
        lineHeight: 1.2,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      h3: {
        paddingTop: 3,
        fontSize: '1.625rem !important', // 26
        lineHeight: 1.2,
        fontWeight: 'inherit',
      },
      h7: {
        fontSize: '1.5rem', // 24
        lineHeight: 1.2,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      h4: {
        fontSize: '1.25rem !important', // 20
        lineHeight: 1.25,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      h5: {
        fontSize: '1rem !important', // 16
        lineHeight: 1.2,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      h6: {
        fontSize: '1.125rem !important', // 18
        lineHeight: 1.2,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      subtitle2: {
        fontSize: '0.9375rem', // 15
        lineHeight: 1.25,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      subtitle1: {
        fontSize: '0.8125rem', // 13
        lineHeight: 1.25,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      body1: {
        fontSize: '0.875rem', // 14
        lineHeight: 1.2,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      body2: {
        fontSize: '0.75rem', // 12
        lineHeight: 1.25,
        paddingTop: 3,
        fontWeight: 'inherit',
      },
      small: {
        fontSize: '0.6875rem', // 11
        lineHeight: 1.25,
        paddingTop: 2.5,
        fontWeight: 'inherit',
      },
    },
  })

  theme.language = language
  theme.isHebrew = isHebrew
  theme.scrollbarWidth = window.innerWidth - document.body.clientWidth

  theme.components = {
    MuiCssBaseline: {
      styleOverrides: {
        html: [
          { '@font-face': abradeLight },
          { '@font-face': abradeRegular },
          { '@font-face': abradeMedium },
          { '@font-face': abradeSemiBold },
          { '@font-face': abradeBold },
          { '@font-face': abradeExtraBold },
          { '@font-face': abradeBlack }
        ],
        'html, body': {
          padding: 0,
          scrollbarWidth: 'thin',
        },
        '.formWrapper': {
          width: 630,
          padding: '32px 110px 40px',
          borderWidth: 2,
          borderColor: theme.palette.grey['100'],
          borderStyle: 'solid',
          borderRadius: 60,
          margin: '32px auto',
          [theme.breakpoints.down(768)]: {
            width: 480,
            borderWidth: 0,
            padding: '16px 75px 30px',
          },
          [theme.breakpoints.down(560)]: {
            width: 420,
            borderWidth: 0,
            padding: '16px 20px 30px',
          },
          [theme.breakpoints.down(460)]: {
            width: 360,
            borderWidth: 0,
            padding: '16px 16px 30px',
          },
          [theme.breakpoints.down(392)]: {
            width: 328,
            borderWidth: 0,
            padding: '16px 16px 30px',
          },
        },
        '.topPanel': {
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5),
          width: '100vw',
          marginLeft: 'calc(328px - 50vw)',
          paddingLeft: 'calc(50vw - 312px)',
          paddingRight: 'calc(50vw - 656px)',
          background: "white",
          [theme.breakpoints.down(1360)]: {
            marginLeft: 'calc(157px - 50vw)',
            paddingLeft: 'calc(50vw - 142px)',
            paddingRight: 'calc(50vw - 472px)',
          },
          [theme.breakpoints.down(960)]: {
            marginLeft: 'calc(315px - 50vw)',
            paddingLeft: 'calc(50vw - 144px)',
            paddingRight: 'calc(50vw - 144px)',
          },
        },
        '.sidebarWrap': {
          position: 'absolute',
          top: theme.spacing(5),
          left: 0,
          right: 0
        },
        '.centralBlock': {
          paddingTop: theme.spacing(5),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(5),
          [theme.breakpoints.down(1024)]: {
            paddingLeft: theme.spacing(0.75),
            paddingRight: 0,
          }
        },
        '.Wrapper-stroked-44': {
          width: 44,
          height: 44,
          minWidth: 44,
          borderColor: theme.palette.designGrey['300'],
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.Wrapper-filled-44': {
          width: 44,
          height: 44,
          minWidth: 44,
          backgroundColor: theme.palette.designGrey['100'],
          borderRadius: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.Wrapper-filled-42': {
          width: 42,
          height: 42,
          minWidth: 42,
          backgroundColor: theme.palette.designGrey['100'],
          borderRadius: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.Wrapper-stroked-42': {
          width: 42,
          height: 42,
          minWidth: 42,
          backgroundColor: theme.palette.background.default,
          borderRadius: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
          borderColor: theme.palette.designGrey['200'],
          borderStyle: 'solid',
          // boxShadow: '0px 2px 16px rgba(0, 22, 38, 0.1)',
        },
        '.Wrapper-stroked-32': {
          width: 32,
          height: 32,
          minWidth: 32,
          background: 'white',
          borderColor: theme.palette.primary.light,
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.iconWrapper50': {
          width: 50,
          height: 50,
          minWidth: 50,
          backgroundColor: theme.palette.background.default,
          borderRadius: 15,
          borderColor: theme.palette.designGrey['200'],
          borderWidth: 1,
          borderStyle: 'solid',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.Wrapper-stroked-40': {
          width: 40,
          height: 40,
          minWidth: 40,
          backgroundColor: theme.palette.background.default,
          borderRadius: 10,
          borderColor: theme.palette.designGrey['200'],
          borderWidth: 1,
          borderStyle: 'solid',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.horizontalRow': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          direction: isHebrew ? 'rtl' : 'ltr',
        },
        '.reverseFlexRow': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          direction: isHebrew ? 'ltr' : 'rtl',
        },
        '.horizontalFlex': {
          display: 'flex',
          alignItems: 'center',
          direction: isHebrew ? 'rtl' : 'ltr',
        },
        '.reverseHorizontalFlex': {
          display: 'flex',
          alignItems: 'center',
          direction: isHebrew ? 'ltr' : 'rtl',
        },
        '.verticalFlex': {
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        '.clearVerticalFlex': {
          display: 'flex',
          alignItems: isHebrew ? 'flex-end' : 'flex-start',
          flexDirection: 'column',
        },
        '.reverseVerticalFlex': {
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
        },
        '.box-flex-center': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.lightButton': {
          backgroundColor: theme.palette.info.dark,
          color: theme.palette.grey["800"],
          borderRadius: 12,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          cursor: 'pointer',
          height: 30,
          '&:hover': {
            color: theme.palette.primary.light,
          }
        },
        '.clickedBox': {
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.primary.light,
            '&.activeBorder, & .activeBorder': {
              borderColor: theme.palette.primary.light,
            },
            '& .MuiTypography-root': {
              color: theme.palette.primary.light,
            },
            '& svg path': {
              fill: theme.palette.primary.light,
            },
          }
        },
        '.backButton': {
          width: 190,
          height: 32,
          borderRadius: 10,
          borderColor: theme.palette.designGrey["300"],
          borderWidth: 1,
          borderStyle: 'solid',
          fontWeight: 700,
        },
        '.leftPanel': {
          paddingRight: 15,
          [theme.breakpoints.down(1440)]: {
            paddingRight: 10,
          },
          [theme.breakpoints.down(960)]: {
            paddingRight: 0,
          },
        },
        '.leftPanelSm': {
          paddingRight: 15,
          [theme.breakpoints.down(1440)]: {
            paddingRight: 10,
          },
          [theme.breakpoints.down(600)]: {
            paddingRight: 0,
          },
        },
        '.rightPanel': {
          paddingLeft: 15,
          [theme.breakpoints.down(1440)]: {
            paddingLeft: 10,
          },
          [theme.breakpoints.down(960)]: {
            paddingLeft: 0,
          },
        },
        '.rightPanelSm': {
          paddingLeft: 15,
          [theme.breakpoints.down(1440)]: {
            paddingLeft: 10,
          },
          [theme.breakpoints.down(600)]: {
            paddingLeft: 0,
          },
        },
        '.hiddenInput': {
          display: 'none',
        },
        '.helpSection': {
          right: 30,
          zIndex: 10,
          bottom: 50,
          position: 'fixed',
          display: 'flex',
          background: 'transparent',
          borderRadius: 12,
          '&:hover .slideBlock': {
            width: 162,
          },
          [theme.breakpoints.down(1440)]: {
            right: 18,
          },
          [theme.breakpoints.down(1280)]: {
            right: 9,
          },
          [theme.breakpoints.down(768)]: {
            right: 12,
          },
          [theme.breakpoints.down(560)]: {
            right: 8,
          },
        },
        '.slideBlock': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: 0,
          transition: 'width 0.8s',
        },
        '.hebrew-detect': {
          direction: isHebrew ? 'rtl' : 'ltr',
        },
        '.text-end-ellipse': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '.transaction-paper-modal': {
          padding: '0 !important',
          '& > button[id="close-modal-icon"]': {
            display: 'none',
            opacity: 0,
          },
        },
        '.qr-code-paper-modal': {
          width: '850px !important',
          padding: '50px 110px !important',
          [theme.breakpoints.down(640)]: {
            padding: '40px 30px',
          },
          [theme.breakpoints.down(520)]: {
            padding: '40px 20px',
          },
        },
        '.wide-modal': {
          width: '850px !important',
          [theme.breakpoints.down(850)]: {
            width: '100% !important',
            padding: '36px !important',
          },
          [theme.breakpoints.down(640)]: {
            width: '100% !important',
            padding: '36px 20px !important',
          },
          '&.withoutPadding': {
            padding: 0,
          }
        },
        '.date-picker-icon': {
          backgroundColor: 'transparent',
          marginBottom: 2,
        },
        '.weight500': {
          fontWeight: '500 !important',
        },
        '.weight600': {
          fontWeight: '600 !important',
        },
        '.weight700': {
          fontWeight: 'bold !important',
        },
        '.weight800': {
          fontWeight: '800 !important',
        },
        '.rotate180': {
          transform: 'rotateZ(180deg)'
        },
        '.onHoverMenu': {
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.background.paper,
            '& .MuiTypography-root': {
              color: theme.palette.primary.light,
            },
          }
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          height: 44,
          borderRadius: 14,
          paddingTop: 7,
          '& .MuiButton-startIcon': {
            marginRight: isHebrew ? -8 : 10,
            marginLeft: isHebrew ? 10 : -8,
          },
        },
        sizeMedium: {
          width: 190,
          minWidth: 190,
        },
        sizeLarge: {
          width: 270,
        },
        sizeSmall: {
          width: 136,
          minWidth: 136,
        },
        fullWidth: {
          width: '100%'
        },
        contained: {
          backgroundColor: theme.palette.designGrey["100"],
          color: theme.palette.grey['600'],
          '&.Mui-focusVisible, &:active': {
            backgroundColor: theme.palette.grey['300'],
          },
          '&:hover': {
            backgroundColor: theme.palette.grey.A200,
          },
          '&:disabled': {
            backgroundColor: theme.palette.grey['100'],
            color: theme.palette.grey['300'],
          },
          '&.active': {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.primary.light,
            '&:hover': {
              backgroundColor: theme.palette.info.dark,
            },
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&.Mui-focusVisible, &:active, &:hover:active': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&:disabled': {
            backgroundColor: theme.palette.grey['100'],
            color: theme.palette.grey['300'],
          },
        },
        containedSecondary: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: '#15D353',
          },
          '&.Mui-focusVisible, &:active, &:hover:active': {
            backgroundColor: theme.palette.secondary.dark,
          },
          '&:disabled': {
            backgroundColor: theme.palette.grey['100'],
            color: theme.palette.grey['300'],
          },
        },
        outlinedInherit: {
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.designGrey['300'],
          color: theme.palette.grey['600'],
          '&:hover': {
            borderColor: theme.palette.grey['600'],
            backgroundColor: theme.palette.background.default,
          },
          '&.Mui-focusVisible, &:active, &:hover:active': {
            borderColor: theme.palette.grey['600'],
            backgroundColor: theme.palette.grey['100'],
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.grey['100'],
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grey['300'],
          },
        },
        outlinedPrimary: {
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.primary.light,
          color: theme.palette.primary.light,
          '& path': {
            fill: theme.palette.primary.light,
          },
          '&:hover path': {
            fill: theme.palette.background.default,
          },
          '&:hover': {
            borderColor: theme.palette.info.main,
            backgroundColor: theme.palette.info.main,
            color: theme.palette.background.default,
          },
          '&.Mui-focusVisible, &:active, &:hover:active': {
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.background.default,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.grey['100'],
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grey['300'],
            '& path': {
              fill: theme.palette.grey['300'],
            },
          },
        },
        outlinedSecondary: {
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
          '& path': {
            fill: theme.palette.error.main,
          },
          '&:hover': {
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.background.default,
            '& svg path': {
              fill: theme.palette.background.default,
            },
          },
          '&.Mui-focusVisible, &:active, &:hover:active': {
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.main,
            color: theme.palette.background.default,
            '& svg path': {
              fill: theme.palette.background.default,
            },
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.grey['100'],
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grey['300'],
            '& svg path': {
              fill: theme.palette.grey['300'],
            },
          },
        },
        text: {
          width: 120,
          height: 36,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.light,
            '& path': {
              fill: theme.palette.primary.light,
            },
          },
          '&:active, &:hover:active': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.dark,
            '& path': {
              fill: theme.palette.primary.dark,
            },
          },
        },
        textInherit: {
          width: 120,
          height: 36,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.light,
            '& path': {
              fill: theme.palette.primary.light,
            },
          },
          '&:active, &:hover:active': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.dark,
            '& path': {
              fill: theme.palette.primary.dark,
            },
          },
        },
        textPrimary: {
          width: 120,
          height: 36,
          color: theme.palette.primary.light,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.dark,
            '& path': {
              fill: theme.palette.primary.dark,
            },
          },
          '&:active, &:hover:active': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.dark,
            '& path': {
              fill: theme.palette.primary.dark,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 30,
          height: 30,
          padding: 0,
          backgroundColor: theme.palette.designGrey['100'],
          '&:hover': {
            backgroundColor: theme.palette.grey['50'],
          },
        },
        colorInherit: {
          backgroundColor: theme.palette.background.default,
          boxShadow: '0px 3px 11px rgba(0, 0, 0, 0.09)',
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
        },
        colorPrimary: {
          backgroundColor: theme.palette.info.dark,
          '&:hover': {
            backgroundColor: theme.palette.info.main,
            '& svg path': {
              fill: theme.palette.background.default,
            },
          },
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.designGrey['400'],
          fontSize: '0.875rem',
          position: 'absolute',
         // bottom: 3,
          top: 70,
          right: theme.isHebrew ? 'auto' : 0,
          left: theme.isHebrew ? 0 : 'auto',
          direction: theme.isHebrew ? 'rtl' : 'ltr',
          lineHeight: '16px',
          marginTop: 0,
          marginLeft: theme.spacing(0.25),
          marginRight: theme.spacing(0.25),
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 20,
          borderRadius: 12,
          [theme.breakpoints.down(600)]: {
            paddingRight: 12,
          },
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          borderStyle: 'solid',
          '&:hover': {
            backgroundColor: theme.palette.designGrey["100"],
          },
          '& .MuiOutlinedInput-root': {
            height: 44,
            borderRadius: 12,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: isHebrew ? 16 : 30,
            paddingLeft: isHebrew ? 30 : 16,
            fontSize: '0.9375rem',
            fontWeight: 500,
            '&.Mui-disabled': {
              backgroundColor: theme.palette.grey['100'],
              cursor: 'default',
              '&:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.designGrey['200'],
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.designGrey['300'],
              borderRadius: 12,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.primary.main,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.error.main,
            },
            '& .MuiAutocomplete-input': {
              cursor: 'pointer',
              fontSize: '0.9375rem',
              fontWeight: 500,
              direction: isHebrew ? 'rtl' : 'ltr',
              paddingLeft: 2,
              paddingRight: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
            '& .MuiIconButton-root, & .MuiIconButton-root:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiOutlinedInput-input': {
              padding: '6px 0 3px',
            }
          },
          '& .MuiInput-underline': {
            outline: 0,
            height: 38,
            borderRadius: 12,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: '2rem',
            fontWeight: 500,
            '&.MuiInputBase-root::before, &.MuiInputBase-root:hover::before': {
              borderBottomWidth: 1,
              borderBottomColor: theme.palette.designGrey['300'],
            },
            '&.Mui-focused::after': {
              borderBottomWidth: 1.5,
            }
          }
        },
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          padding: 30,
          '&.MuiSnackbar-anchorOriginTopRight': {
            top: 24,
          },
        },
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
          justifyContent: 'center',
          '& .MuiSnackbarContent-action': {
            color: theme.palette.grey['600'],
            margin: 0,
            padding: 0,
            position: 'absolute',
            top: 2,
            right: 2,
          },
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: theme.palette.background.default,
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          boxShadow: 'none',
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          }
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 20,
          fontSize: '0.6875rem',
          borderRadius: 9,
          paddingTop: 2,
          backgroundColor: theme.palette.grey['100'],
          color: theme.palette.text.primary,
        },
        label: {
          paddingRight: 16,
          paddingLeft: 16,
        },
        colorPrimary: {
          backgroundColor: theme.palette.info.light,
          color: '#3A79A6',
        },
        colorSecondary: {
          backgroundColor: theme.palette.secondary.light,
          color: '#0F993C',
        },
        icon: {
          marginLeft: isHebrew ? -6 : 5,
          marginRight: isHebrew ? 5 : -6,
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 1,
          backgroundColor: theme.palette.designGrey["200"],
        },
        light: {
          height: 1,
          backgroundColor: theme.palette.divider,
        },
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
          padding: 0,
          color: theme.palette.designGrey["300"],
        },
        colorPrimary: {
          backgroundColor: '#fff',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 60,
          height: 30,
          padding: 0,
          boxSizing: 'content-box',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'white',
          borderRadius: 15,
          '&:hover': {
            borderColor: '#33A7FF'
          }
        },
        track: {
          backgroundColor: '#EEF3F6',
          opacity: 1,
          borderRadius: 15,
          // '& .Mui-checked': {
          //   backgroundColor: '#D9EEFF',
          //   opacity: 1,
          // }
        },
        input: {
          left: -8,
          top: -10,
          width: 62,
          height: 30,
        },
        switchBase: {
          padding: 0,
          width: 10,
          height: 10,
          margin: 10,
          color: '#556169',
          '&.Mui-checked': {
            transform: 'translate(25px)',
            width: 20,
            height: 20,
            margin: 5,
            '& .MuiSwitch-thumb': {
              width: 20,
              height: 20,
              boxShadow: 'none',
            },
            '& .MuiSwitch-input': {
              left: -30,
              top: -5,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: '#D9EEFF',
              opacity: 1,
            },
          }
        },
        thumb: {
          width: 10,
          height: 10,
          boxShadow: 'none',
        }
      }
    },
    // MuiSwitch: {
    //   styleOverrides: {
    //     root: {
    //       width: 62,
    //       height: 32,
    //       padding: 0,
    //       borderRadius: 16,
    //       borderWidth: 1,
    //       borderStyle: 'solid',
    //       borderColor: 'white',
    //       '&:hover': {
    //         borderColor: '#33A7FF'
    //       }
    //     },
    //     track: {
    //       backgroundColor: '#EEF3F7',
    //       opacity: 1,
    //       borderRadius: 16,
    //       '&.Mui-checked': {
    //         backgroundColor: '#D9EEFF',
    //         opacity: 1,
    //       }
    //     },
    //     switchBase: {
    //       width: 10,
    //       height: 10,
    //       padding: 5,
    //       top: 10,
    //       left: 10,
    //       backgroundColor: '#556169',
    //       '&:hover': {
    //         backgroundColor: '#556169',
    //       },
    //       '&.Mui-checked': {
    //         width: 10,
    //         height: 10,
    //         padding: 10,
    //         backgroundColor: '#33A7FF',
    //         transform: 'translate(24px)',
    //         top: 5,
    //         '&:hover': {
    //           backgroundColor: '#33A7FF',
    //         },
    //       }
    //     },
    //   }
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 0',
          borderBottom: '1px solid #eef3f7',
          fontSize: 11,
          fontWeight: 500,
          color: theme.palette.text.primary,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 16,
          fontSize: '0.875rem',
          lineHeight: 1.3,
          textAlign: 'center',
          borderRadius: 12,
          direction: theme.isHebrew ? 'rtl' : 'ltr',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          boxShadow: '0px 4px 12px rgba(0, 46, 79, 0.1)',
        },
        arrow: {
          '&:before': {
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 4px 12px rgba(0, 46, 79, 0.1)',
          },
        },
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.background.default,
          borderRadius: 12,
          boxShadow: '0px 4px 12px rgba(0, 46, 79, 0.08)',
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          direction: isHebrew ? 'rtl' : 'ltr',
        }
      }
    }
  }

  return responsiveFontSizes(theme)
}

export default createAppTheme
