import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()(({palette, spacing, isHebrew}) => ({
  root: {
    direction: isHebrew ? 'rtl' : 'ltr',
    marginLeft: isHebrew ? 16 : -11,
    marginRight: isHebrew ? -11 : 16,
    alignItems: 'flex-start',
    gap: spacing(0.5),
    '& .MuiIconButton-root': {
      width: 40,
      height: 40,
      minWidth: 40,
      padding: '4px 0 0',
      backgroundColor: 'transparent',
      alignItems: 'flex-start',
    },
  },
  transparentBack: {
    backgroundColor: 'transparent',
    '& .MuiIconButton-root': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 20,
    minWidth: 20,
    height: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: palette.background.default,
    borderColor: palette.designGrey['200'],
    zIndex: 2,
    '&:hover': {
      borderColor: palette.primary.main,
      backgroundColor: palette.designGrey['100'],
    },
  },
  checkedIcon: {
    width: 20,
    height: 20,
    minWidth: 20,
    borderRadius: 4,
    backgroundColor: palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: '0.9375rem',
    color: palette.designGrey['500'],
    direction: isHebrew ? 'rtl' : 'ltr',
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 4,
  },
  circle: {
    display: 'flex',
    width: 20,
    minWidth: 20,
    height: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    padding: 4,
  }
}))

export default useStyles
