import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import {
  ITransferData,
  ITransactionMeta,
  ILocation,
  IPriceResponse,
  IBankDetail,
  IBranchBank,
  ICity,
  IDTOnePayload,
  IDTOneResponse,
  initialState,
  clearPrice,
  ICityLocation,
  IMobileOperator, IComplianceRules,
} from 'model/transfer.model'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'

const transferSlice = createSlice({
  name: 'transfer',
  initialState: initialState(),
  reducers: {
    setCalculatorData(state, action: PayloadAction<Partial<ITransferData>>) {
      if (!action.payload.mode || action.payload.mode !== 'repeat') {
        state.transferData = { ...current(state).transferData, ...action.payload }
      } else {
        const { transferData } = initialState()
        state.transferData = {
          ...transferData,
          allowWalletBalance: action.payload.allowWalletBalance,
          sourceOfFunds: action.payload.sourceOfFunds,
          useOfFunds: action.payload.useOfFunds,
          occupation: action.payload.occupation,
          recipientWalletId: action.payload.recipientWalletId,
          senderWalletId: action.payload.senderWalletId,
          comment: action.payload.comment,
        }
      }
    },
    setTransferData(state, action: PayloadAction<Partial<ITransferData>>) {
      state.transferData = { ...current(state).transferData, ...action.payload }
    },
    clearTransferData(state) {
      const { transferData } = initialState()
      state.transferData = transferData
      state.activeComplianceRules = {}
    },
    setTransactionMeta(state, action: PayloadAction<ITransactionMeta>) {
      if (state.transactionMeta) {
        state.transactionMeta = { ...current(state).transactionMeta, ...action.payload }
      } else {
        state.transactionMeta = action.payload
      }
    },
    setSourceCurrencies(state, action: PayloadAction<string[]>) {
      state.transferData.sourceCurrencies = action.payload
    },
    setDestinationCurrencies(state, action: PayloadAction<string[]>) {
      state.transferData.destinationCurrencies = action.payload
    },
    getPriceRequest(state) {
      state.loading = 'pricePending'
      state.transferData.message = ''
    },
    getPriceSuccess(state, action: PayloadAction<IPriceResponse>) {
      state.loading = 'priceSuccess'
      state.transferData = { ...current(state).transferData, ...action.payload }
    },
    getPriceFailed(state, action: PayloadAction<string>) {
      state.loading = 'priceFailed'
      state.priceError = action.payload
      state.transferData = { ...current(state).transferData, ...clearPrice }
    },
    getDiscountRequest(state, action: PayloadAction<{ promoCode: string }>) {
      state.loading = 'discountPending'
    },
    getDiscountSuccess(state, action: PayloadAction<{ promoCodeValid: boolean }>) {
      state.loading = 'discountSuccess'
      state.transferData = { ...current(state).transferData, ...action.payload }
    },
    getDiscountFailed(state) {
      state.loading = 'discountFailed'
    },
    getLocationsSettingsRequest(state, action: PayloadAction<{ country: string, operatorId: string }>) {
      state.loading = 'locationPending'
    },
    getLocationsSettingsSuccess(state, action: PayloadAction<boolean>) {
      state.loading = 'locationSuccess'
      state.transferData.hasMultiplePaymentType = action.payload
    },
    getLocationsSettingsFailed(state) {
      state.loading = 'locationFailed'
    },
    getLocationsRequest(state) {
      state.loading = 'locationPending'
    },
    getLocationsSuccess(state, action: PayloadAction<ILocation[]>) {
      state.loading = 'locationSuccess'
      state.transferData.pickupLocations = action.payload
    },
    getLocationsFailed(state) {
      state.loading = 'locationFailed'
    },
    getBanksRequest(state) {
      state.loading = 'bankPending'
    },
    getBanksSuccess(state, action: PayloadAction<IBankDetail[]>) {
      state.loading = 'bankSuccess'
      state.transferData.banksByCountry = action.payload
    },
    getBanksFailed(state) {
      state.loading = 'bankFailed'
    },
    getBranchBankRequest(state, action: PayloadAction<{ bankCode: string }>) {
      state.loading = 'bankPending'
    },
    getBranchBankSuccess(state, action: PayloadAction<IBranchBank[]>) {
      state.loading = 'bankSuccess'
      state.transferData.bankBranches = action.payload
    },
    getBranchBankFailed(state) {
      state.loading = 'locationFailed'
    },
    getCitiesRequest(state) {
      state.loading = 'locationPending'
    },
    getCitiesSuccess(state, action: PayloadAction<ICity[]>) {
      state.loading = 'locationSuccess'
      state.transferData.citiesByCountry = action.payload
      const defaultCity = action.payload
        .find((city) => city.name.toLowerCase().includes('kyiv') || city.name.toLowerCase().includes('kiev'))
      if (defaultCity) {
        state.transferData.pickupCityCode = defaultCity.code
        state.transferData.pickupCityName = defaultCity.name
      } else {
        state.transferData.pickupCityCode = action.payload[0].code
        state.transferData.pickupCityName = action.payload[0].name
      }
    },
    getCitiesFailed(state) {
      state.loading = 'locationFailed'
    },
    getCityLocationsRequest(state, action: PayloadAction<{ city: string }>) {
      state.loading = 'locationPending'
    },
    getCityLocationsSuccess(state, action: PayloadAction<ILocation[]>) {
      state.loading = 'locationSuccess'
      state.transferData.pickupLocations = action.payload
    },
    getCityLocationsFailed(state) {
      state.loading = 'locationFailed'
      state.transferData.pickupLocations = []
    },
    getCountryStatesRequest(state) {
      state.loading = 'locationPending'
    },
    getCountryStatesSuccess(state, action: PayloadAction<string[]>) {
      state.loading = 'locationSuccess'
      state.transferData.statesByCountry = action.payload
    },
    getCountryStatesFailed(state) {
      state.loading = 'locationFailed'
      state.transferData.statesByCountry = []
    },
    getStateCitiesRequest(state) {
      state.loading = 'locationPending'
    },
    getStateCitiesSuccess(state, action: PayloadAction<ICity[]>) {
      state.loading = 'locationSuccess'
      state.transferData.citiesByState = action.payload
    },
    getStateCitiesFailed(state) {
      state.loading = 'locationFailed'
      state.transferData.citiesByState = []
    },
    getStateCityLocationsRequest(state) {
      state.loading = 'locationPending'
    },
    getStateCityLocationsSuccess(state, action: PayloadAction<ICityLocation[]>) {
      state.loading = 'locationSuccess'
      state.transferData.cityLocations = action.payload
    },
    getStateCityLocationsFailed(state) {
      state.loading = 'locationFailed'
      state.transferData.cityLocations = []
    },
    getMobileOperatorsRequest(state) {
      state.loading = 'operatorsPending'
    },
    getMobileOperatorsSuccess(state, action: PayloadAction<IMobileOperator[]>) {
      state.loading = 'operatorsSuccess'
      state.transferData.mobileOperators = action.payload
    },
    getMobileOperatorsFailed(state) {
      state.loading = 'operatorsFailed'
      state.transferData.mobileOperators = []
    },
    makeTransactionRequest(state) {
      state.loading = 'pending'
    },
    makeTransactionSuccess(state, action: PayloadAction<ITransactionMeta>) {
      state.loading = 'success'
      state.transactionMeta = action.payload
    },
    makeTransactionFailed(state, action: PayloadAction<string>) {
      state.loading = 'failed'
      state.transferData.errorMessage = action.payload
    },
    validateTransactionRequest(state) {
      state.loading = 'pending'
      if (state.transactionMeta) {
        state.transactionMeta.errorMessage = ''
      }
    },
    validateTransactionSuccess(state, action: PayloadAction<boolean>) {
      state.loading = 'success'
      if (state.transactionMeta) {
        state.transactionMeta.validationPassed = action.payload
      }
    },
    validateTransactionFailed(state, action: PayloadAction<string>) {
      state.loading = 'failed'
      if (state.transactionMeta) {
        state.transactionMeta.errorMessage = action.payload
      }
    },
    confirmTransactionRequest(state, action: PayloadAction<any>) {
      state.loading = 'pending'
      if (state.transactionMeta) {
        state.transactionMeta.errorMessage = ''
      }
    },
    confirmTransactionSuccess(state, action: PayloadAction<ITransactionMeta>) {
      state.loading = 'success'
      state.transactionMeta = { ...current(state).transactionMeta, ...action.payload }
    },
    confirmTransactionFailed(state, action: PayloadAction<string>) {
      state.loading = 'failed'
      if (state.transactionMeta) {
        state.transactionMeta.errorMessage = action.payload
      }
    },
    getDTOneDataRequest(state, action: PayloadAction<Omit<IDTOnePayload, 'url'>>) {
      state.loading = 'pendingDTOne'
    },
    getDTOneDataSuccess(state, action: PayloadAction<IDTOneResponse>) {
      state.loading = 'success'
      const transferData = {
        products: action.payload.products,
        mobileOperatorName: action.payload.operatorName,
        amountSentSrc: 0,
        amountReceivedDest: 0,
        amountSenderToPay: 0,
        organizationFeeAmountSrc: 0,
        fxRateSrcDestWithCommission: 0,
      }
      state.transferData = { ...current(state).transferData, ...transferData }
    },
    getDTOneDataFailed(state) {
      state.loading = 'failed'
      const clearObject = {
        phoneNumber: '',
        mobileOperatorName: '',
        amountSentSrc: 0,
        amountReceivedDest: 0,
        amountSenderToPay: 0,
        organizationFeeAmountSrc: 0,
        fxRateSrcDestWithCommission: 0,
        products: [],
      }
      state.transferData = { ...state.transferData, ...clearObject }
    },
    applyComplianceRule(state, action: PayloadAction<IComplianceRules>) {
      state.activeComplianceRules = { ...current(state).activeComplianceRules, ...action.payload }
    }
  },
})

export const {
  setCalculatorData,
  setTransferData,
  clearTransferData,
  setTransactionMeta,
  setDestinationCurrencies,
  setSourceCurrencies,
  getPriceFailed,
  getPriceRequest,
  getPriceSuccess,
  getLocationsSettingsFailed,
  getLocationsSettingsRequest,
  getLocationsSettingsSuccess,
  getLocationsFailed,
  getLocationsRequest,
  getLocationsSuccess,
  getBanksFailed,
  getBanksRequest,
  getBanksSuccess,
  getBranchBankFailed,
  getBranchBankRequest,
  getBranchBankSuccess,
  getCitiesFailed,
  getCitiesRequest,
  getCitiesSuccess,
  getCityLocationsFailed,
  getCityLocationsRequest,
  getCityLocationsSuccess,
  makeTransactionFailed,
  makeTransactionRequest,
  makeTransactionSuccess,
  validateTransactionFailed,
  validateTransactionRequest,
  validateTransactionSuccess,
  confirmTransactionRequest,
  confirmTransactionFailed,
  confirmTransactionSuccess,
  getDTOneDataFailed,
  getDTOneDataRequest,
  getDTOneDataSuccess,
  getCountryStatesFailed,
  getCountryStatesRequest,
  getCountryStatesSuccess,
  getStateCitiesFailed,
  getStateCitiesRequest,
  getStateCitiesSuccess,
  getStateCityLocationsFailed,
  getStateCityLocationsRequest,
  getStateCityLocationsSuccess,
  getDiscountRequest,
  getDiscountSuccess,
  getDiscountFailed,
  getMobileOperatorsSuccess,
  getMobileOperatorsFailed,
  getMobileOperatorsRequest,
  applyComplianceRule,
} = transferSlice.actions

export const getPriceActions = {
  [REQUEST]: getPriceRequest,
  [SUCCESS]: getPriceSuccess,
  [FAIL]: getPriceFailed,
}

export const getDiscountActions = {
  [REQUEST]: getDiscountRequest,
  [SUCCESS]: getDiscountSuccess,
  [FAIL]: getDiscountFailed,
}

export const getLocationsSettingsActions = {
  [REQUEST]: getLocationsSettingsRequest,
  [SUCCESS]: getLocationsSettingsSuccess,
  [FAIL]: getLocationsSettingsFailed,
}

export const getLocationsActions = {
  [REQUEST]: getLocationsRequest,
  [SUCCESS]: getLocationsSuccess,
  [FAIL]: getLocationsFailed,
}

export const getBanksActions = {
  [REQUEST]: getBanksRequest,
  [SUCCESS]: getBanksSuccess,
  [FAIL]: getBanksFailed,
}

export const getBranchesActions = {
  [REQUEST]: getBranchBankRequest,
  [SUCCESS]: getBranchBankSuccess,
  [FAIL]: getBranchBankFailed,
}

export const getCountryStatesActions = {
  [REQUEST]: getCountryStatesRequest,
  [SUCCESS]: getCountryStatesSuccess,
  [FAIL]: getCountryStatesFailed,
}

export const getStateCitiesActions = {
  [REQUEST]: getStateCitiesRequest,
  [SUCCESS]: getStateCitiesSuccess,
  [FAIL]: getStateCitiesFailed,
}

export const getStateCityLocationsActions = {
  [REQUEST]: getStateCityLocationsRequest,
  [SUCCESS]: getStateCityLocationsSuccess,
  [FAIL]: getStateCityLocationsFailed,
}

export const getCitiesActions = {
  [REQUEST]: getCitiesRequest,
  [SUCCESS]: getCitiesSuccess,
  [FAIL]: getCitiesFailed,
}

export const getCityLocationsActions = {
  [REQUEST]: getCityLocationsRequest,
  [SUCCESS]: getCityLocationsSuccess,
  [FAIL]: getCityLocationsFailed,
}

export const getMobileOperatorsActions = {
  [REQUEST]: getMobileOperatorsRequest,
  [SUCCESS]: getMobileOperatorsSuccess,
  [FAIL]: getMobileOperatorsFailed,
}

export const makeTransactionActions = {
  [REQUEST]: makeTransactionRequest,
  [SUCCESS]: makeTransactionSuccess,
  [FAIL]: makeTransactionFailed,
}

export const validateTransactionActions = {
  [REQUEST]: validateTransactionRequest,
  [SUCCESS]: validateTransactionSuccess,
  [FAIL]: validateTransactionFailed,
}

export const confirmTransactionActions = {
  [REQUEST]: confirmTransactionRequest,
  [SUCCESS]: confirmTransactionSuccess,
  [FAIL]: confirmTransactionFailed,
}

export const getDTOneDataActions = {
  [REQUEST]: getDTOneDataRequest,
  [SUCCESS]: getDTOneDataSuccess,
  [FAIL]: getDTOneDataFailed,
}

export default transferSlice.reducer
