import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import {
  ITransaction,
  IOperation,
  ITransactionDetail,
  ITransactionDocument,
  ITransactionHistory,
  IWalletHistory,
  initialState,
  IFilteredPayload,
  IWalletHistoryPayload,
  IFavoriteTransaction,
  IAnalyticaPayload,
  IAnalytics,
  IOperationDetail,
} from 'model/transaction.model'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'

const transactionSlice = createSlice({
  name: 'transactions',
  initialState: initialState(),
  reducers: {
    getTransactionsRequest(state) {
      state.loading = 'pending'
    },
    getTransactionsSuccess(state, action: PayloadAction<{ transactions: ITransaction[] }>) {
      state.loading = 'success'
      state.recentTransactions = action.payload.transactions
    },
    getTransactionsFail(state) {
      state.loading = 'failed'
    },
    getFilteredRequest(state, action: PayloadAction<IFilteredPayload>) {
      state.loading = 'pending'
    },
    getFilteredFail(state) {
      state.loading = 'failed'
    },
    getFilteredSuccess(state, action: PayloadAction<ITransactionHistory>) {
      state.loading = 'success'
      state.transactionHistory = action.payload
    },
    getWalletHistoryRequest(state, action: PayloadAction<IWalletHistoryPayload>) {
      state.loading = 'pending'
      state.walletHistory.walletId = action.payload.walletId
      state.walletHistory.cardId = action.payload.cardId
    },
    getWalletHistoryFail(state) {
      state.loading = 'failed'
    },
    getWalletHistorySuccess(state, action: PayloadAction<IWalletHistory>) {
      state.loading = 'success'
      state.walletHistory = action.payload
    },
    getRecentOperations(state, action: PayloadAction<IOperation[]>) {
      state.loading = 'success'
      state.recentOperations = action.payload
    },
    getCurrentTransactionRequest(state, action: PayloadAction<string>) {
      state.transactionLoading = 'pending'
    },
    getCurrentTransactionFail(state) {
      state.transactionLoading = 'failed'
    },
    getCurrentTransactionSuccess(state, action: PayloadAction<ITransactionDetail | null>) {
      state.currentTransaction = action.payload
      state.transactionLoading = 'success'
    },
    getCurrentOperationRequest(state, action: PayloadAction<IOperation>) {
      state.transactionLoading = 'pending'
    },
    getCurrentOperationFail(state) {
      state.transactionLoading = 'failed'
    },
    getCurrentOperationSuccess(state, action: PayloadAction<IOperationDetail | IOperation | null>) {
      state.transactionLoading = 'success'
      state.currentOperation = action.payload
    },
    getMonthAnalyticaRequest(state, action: PayloadAction<IAnalyticaPayload>) {
      state.analyticaLoading = 'pending'
    },
    getMonthAnalyticaFail(state) {
      state.analyticaLoading = 'failed'
    },
    getMonthAnalyticaSuccess(state, action: PayloadAction<IAnalytics>) {
      state.analyticaLoading = 'success'
      state.analytics = { ...action.payload }
    },
    getFavoriteRequest(state) {
      state.favoriteLoading = 'pending'
    },
    getFavoriteFail(state) {
      state.favoriteLoading = 'failed'
    },
    getFavoriteSuccess(state, action: PayloadAction<IFavoriteTransaction[]>) {
      state.favoriteTransactions = action.payload
      state.favoriteLoading = 'success'
    },
    addFavoriteRequest(state, action: PayloadAction<string>) {
      state.favoriteLoading = 'pending'
    },
    addFavoriteFail(state) {
      state.favoriteLoading = 'failed'
    },
    addFavoriteSuccess(state) {
      state.favoriteLoading = 'success'
    },
    deleteFavoriteRequest(state, action: PayloadAction<string>) {
      state.favoriteLoading = 'pending'
    },
    deleteFavoriteFail(state) {
      state.favoriteLoading = 'failed'
    },
    deleteFavoriteSuccess(state) {
      state.favoriteLoading = 'success'
    },
    setTransactionDocuments(state, action: PayloadAction<ITransactionDocument[]>) {
      if (state.currentTransaction) {
        state.currentTransaction.documents = action.payload
      }
    },
    uploadTransactionDocumentRequest(state) {
      state.documentLoading = 'pending'
    },
    uploadTransactionDocumentSuccess(state) {
      state.documentLoading = 'success'
    },
    uploadTransactionDocumentFail(state) {
      state.documentLoading = 'failed'
    },
    rejectTransactionRequest(state) {
      state.transactionLoading = 'pending'
    },
    rejectTransactionFail(state) {
      state.transactionLoading = 'failed'
    },
    rejectTransactionSuccess(state, action: PayloadAction<ITransaction>) {
      state.transactionLoading = 'success'
      const list = current(state).transactionHistory.list
        .map(transaction => transaction.transactionId === action.payload.transactionId
          ? action.payload
          : transaction
        )
      const recentTransactions = current(state).recentTransactions
        .map(transaction => transaction.transactionId === action.payload.transactionId
          ? action.payload
          : transaction
        )
      state.transactionHistory = { ...current(state).transactionHistory, list };
      state.recentTransactions = recentTransactions;
    },
  },
})

export const {
  getTransactionsFail,
  getTransactionsRequest,
  getTransactionsSuccess,
  getFilteredFail,
  getFilteredRequest,
  getFilteredSuccess,
  getCurrentTransactionFail,
  getCurrentTransactionSuccess,
  getCurrentTransactionRequest,
  setTransactionDocuments,
  rejectTransactionFail,
  rejectTransactionRequest,
  rejectTransactionSuccess,
  uploadTransactionDocumentFail,
  uploadTransactionDocumentRequest,
  uploadTransactionDocumentSuccess,
  getWalletHistoryRequest,
  getWalletHistoryFail,
  getWalletHistorySuccess,
  getRecentOperations,
  getFavoriteFail,
  getFavoriteRequest,
  getFavoriteSuccess,
  addFavoriteFail,
  addFavoriteRequest,
  addFavoriteSuccess,
  deleteFavoriteFail,
  deleteFavoriteRequest,
  deleteFavoriteSuccess,
  getMonthAnalyticaFail,
  getMonthAnalyticaRequest,
  getMonthAnalyticaSuccess,
  getCurrentOperationFail,
  getCurrentOperationRequest,
  getCurrentOperationSuccess,
} = transactionSlice.actions

export const getTransactionsActions = {
  [REQUEST]: getTransactionsRequest,
  [SUCCESS]: getTransactionsSuccess,
  [FAIL]: getTransactionsFail,
}

export const getFilteredActions = {
  [REQUEST]: getFilteredRequest,
  [SUCCESS]: getFilteredSuccess,
  [FAIL]: getFilteredFail,
}

export const getWalletHistoryActions = {
  [REQUEST]: getWalletHistoryRequest,
  [SUCCESS]: getWalletHistorySuccess,
  [FAIL]: getWalletHistoryFail,
}

export const getCurrentTransactionActions = {
  [REQUEST]: getCurrentTransactionRequest,
  [SUCCESS]: getCurrentTransactionSuccess,
  [FAIL]: getCurrentTransactionFail,
}

export const getCurrentOperationActions = {
  [REQUEST]: getCurrentOperationRequest,
  [SUCCESS]: getCurrentOperationSuccess,
  [FAIL]: getCurrentOperationFail,
}

export const getMonthAnalyticaActions = {
  [REQUEST]: getMonthAnalyticaRequest,
  [SUCCESS]: getMonthAnalyticaSuccess,
  [FAIL]: getMonthAnalyticaFail,
}

export const getFavoriteTransactionActions = {
  [REQUEST]: getFavoriteRequest,
  [SUCCESS]: getFavoriteSuccess,
  [FAIL]: getFavoriteFail,
}

export const addFavoriteTransactionActions = {
  [REQUEST]: addFavoriteRequest,
  [SUCCESS]: addFavoriteSuccess,
  [FAIL]: addFavoriteFail,
}

export const deleteFavoriteTransactionActions = {
  [REQUEST]: deleteFavoriteRequest,
  [SUCCESS]: deleteFavoriteSuccess,
  [FAIL]: deleteFavoriteFail,
}

export const uploadTransactionDocumentActions = {
  [REQUEST]: uploadTransactionDocumentRequest,
  [SUCCESS]: uploadTransactionDocumentSuccess,
  [FAIL]: uploadTransactionDocumentFail,
}

export const rejectTransactionActions = {
  [REQUEST]: rejectTransactionRequest,
  [SUCCESS]: rejectTransactionSuccess,
  [FAIL]: rejectTransactionFail,
}

export default transactionSlice.reducer
